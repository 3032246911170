<div class="col-xs-12 pt-1 pb-1" *ngIf="!isLoading">
    <div class="main-box clearfix col-xs-12 mb-0">
        <div class="main-box-body clearfix col-xs-12 px-0">
            <div class="col-xs-12 px-0 pl-4 pt-2 mt-4 d-flex justify-content-between align-items-center"
                [ngClass]="{'mb-5': !isSharedReq}">
                <div>
                    <h2 class="requisition-description-heading font-bold">Job Description</h2>
                </div>
                <div class="pull-right pr-4 mt-2" *ngIf="currentJobStatus !== 'DRAFT' && !isSharedReq">
                    <div class="btn-group revert-dropdown">
                        <button type="button" class="btn btn-danger" data-toggle="dropdown">Revert to</button>
                        <button type="button" class="btn btn-danger dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <span class="caret"></span>
                            <span class="sr-only">Toggle Dropdown</span>
                        </button>
                        <ul class="dropdown-menu">
                            <li>
                                <a class="pl-4" (click)="revertToJobDescriptionFile()">
                                    <span class="pl-3">Job description file</span>
                                </a>
                            </li>
                            <li>
                                <a class="pl-4" (click)="revertToSavedChanges()">
                                    <span class="pl-3">Saved changes</span>
                                </a>
                            </li>
                            <li>
                                <a class="pl-4" (click)="revertToOriginalActivatedVersion()">
                                    <span class="pl-3">Original Activated Version</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 mb-3 pl-4" *ngIf="!isSharedReq">
                <div class="col-xs-12 pl-0">
                    <div class="col-xs-5 pl-0">
                        <!-- job title -->
                        <div class="form-group">
                            <label>Title <em>*</em> : </label>
                            <a *ngIf="currentJobStatus !== 'DRAFT'" class="pl-2" (click)="showJobProfile()" style="color: black; font-size: 100%; font-weight: bold;" tooltip="{{jobTitle}}">
                                <span class="control-label">{{jobTitle}}</span>
                            </a>
                            <i class="fas fa-pencil-alt cursor-pointer" tooltip="Edit Title" (click)="editTitleFlag = !editTitleFlag" *ngIf="isUploadRequisition"></i>
                            <input *ngIf="(currentJobStatus == 'DRAFT') && (editTitleFlag || !isUploadRequisition)" type="text" class="form-control" id="jobTitle" [(ngModel)]="jobTitle"
                                    name="jobTitle" style="border-width: 1px;width: 100%" (change)="onJobTitleChange()">
                        </div>
                    </div>
                    <div class="col-xs-5">
                        <!-- Location -->
                        <div class="form-group">
                            <label>Location : </label>
                            <span tooltip="{{jobLocation}}" style="font-size: 12px; font-weight: bold; cursor:pointer;">
                                {{jobLocation}}
                            </span>
                            <i class="fas fa-pencil-alt cursor-pointer" tooltip="Edit Location" (click)="updateLocation()"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-12 mt-3">
                <div class="col-xs-6 mt-2 candidate-skills-item pr-2">
                    <div class="skill-category-container py-4 px-3">
                        <div class="pb-3">
                            <h3 style="height:32px; border-color: #e7ebee;" class="skills-container d-flex justify-content-between mt-0">
                                <strong>
                                    <span class="pr-3">Quick Add</span>
                                </strong>
                            </h3>
                        </div>
                        <div class="mb-5 pb1">
                            <div class="col-xs-8 pl-0">
                                <input #newSkillsInput id="skillsInput" style="font-size: 16px; width:100%;bottom: -5px"
                                    [(ngModel)]="newSkill.displayName" [typeahead]="allSkillsObservable" [typeaheadAsync]="true"
                                    typeaheadOptionField="name" class="d-inline-block mr-3 add-new-skill-input position-relative"
                                    placeholder="Enter Details Here"
                                    (typeaheadOnSelect)="onRequisitionSkillSelect($event, newSkill, newSkillsInput)"
                                    (focus)="newSkill.typeAheadSelected = false" container="body" [typeaheadScrollable]="true"
                                    [typeaheadOptionsInScrollableView]="8" (ngModelChange)="onSearchSkillChange($event)"
                                    (keyup.enter)="addNewSkill(newSkill.displayName, 'onEnter', newSkillsInput, newSkill)"
                                    (keyup.tab)="newSkill.typeAheadSelected = false" autocomplete="off" [disabled]="isViewOnly"
                                    [typeaheadOptionsLimit]="100">
                            </div>
                            <div class="col-xs-3 width-fit-content">
                                <span class="pt-1 d-flex justify-content-end mt-2 mandatory-favorite-icon">
                                    <span *ngIf="!newSkill.niceToHave && !newSkill.preferred && newSkill.mandatory"
                                        class="cursor-pointer color-red pr-3" tooltip="Mandatory" container="true"
                                        (click)="updateMandatory(newSkill)" style="position: relative; bottom: 1px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 1200 1200">
                                            <path fill="red"
                                                d="M489.838 29.354v443.603L68.032 335.894 0 545.285l421.829 137.086-260.743 358.876 178.219 129.398L600.048 811.84l260.673 358.806 178.146-129.398-260.766-358.783L1200 545.379l-68.032-209.403-421.899 137.07V29.443H489.84z" />
                                        </svg>
                                    </span>
                                    <span *ngIf="!newSkill.niceToHave && !newSkill.mandatory && !newSkill.preferred"
                                        class="cursor-pointer pr-3" tooltip="Mandatory" container="true"
                                        (click)="updateMandatory(newSkill)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 238 272">
                                            <path
                                                d="M100 68.1c0 30.5-.2 37-1.3 36.6-.8-.3-9.9-3.3-20.3-6.7s-25.6-8.4-33.8-11.1-15.1-4.8-15.2-4.6c-.4.4-10.6 32.2-10.8 33.5-.1 1.2 46 16.9 64 21.9l6.1 1.6-20.5 28.1c-11.2 15.5-21.4 29.1-22.5 30.4-1.1 1.2-1.6 2.2-1 2.2.5 0 7.2 4.5 14.8 10 7.5 5.6 14.1 10.2 14.5 10.3.6.2 41.9-55.6 43.5-58.8.3-.7.7-.7 1 0 1.7 3.4 42.9 59 43.6 58.8.4-.2 6.7-4.6 14-9.9s13.8-9.8 14.6-10.1c1.1-.4 1-.9-.4-2.5-1-1.1-11-14.6-22.3-30.1l-20.5-28.2 10.5-3.2c34.5-10.7 59.5-19.3 59.3-20.5-.2-1.4-10.3-33.1-10.6-33.5-.2-.2-64.8 20.6-68.9 22.2-1.7.7-1.8-1.5-1.8-36.4V31h-36zm32-32.2c.6 1.3 1 14.1 1 34.7 0 29.1.2 32.9 1.7 34.6.9 1 2.4 1.8 3.4 1.8 1.1 0 13.9-3.9 28.6-8.6 37.1-12 37.3-12 38.3-10.5 1.9 3.2 8.3 26.2 7.4 27-.5.4-14.6 5.2-31.4 10.6s-31.5 10.4-32.7 11.2c-4.8 2.9-3.7 4.8 24.7 43.3 5.9 8 11.2 15.5 11.9 16.7 1.1 2.2.5 2.8-9.6 10.3-5.9 4.4-11.5 8-12.5 8s-9.1-10.2-21.3-27c-10.9-14.9-20.6-27.5-21.7-28.1-1.4-.8-2.5-.7-4.1.4-1.2.8-10.9 13.4-21.6 28.1C83.5 203 74.1 215 73.3 215c-1.4 0-16.5-10.3-21.1-14.3-2.7-2.5-4.7.8 19.5-32.6 17.8-24.7 19.9-28.5 17-30.9-.7-.6-13.2-5-27.7-9.7-14.6-4.8-29-9.5-32-10.5s-5.6-2.5-5.8-3.3C22.6 111 31 87 32.5 87s51.7 15.9 58.8 18.6c4.9 1.8 7.6 1.8 9.9-.3 1.7-1.5 1.8-3.9 1.8-35.3 0-24.8.3-33.9 1.2-34.8.8-.8 5.4-1.2 14-1.2 11.6 0 12.8.2 13.8 1.9z"
                                                fill="red" stroke="red" stroke-width="10" />
                                        </svg>
                                    </span>
                                    <span *ngIf="!newSkill.mandatory && !newSkill.niceToHave"
                                        class="cursor-pointer text-success fa-check-circle pr-3 mr-1 pt-1" style="font-size: 16px;"
                                        [ngClass]="{'fas': newSkill.preferred, 'far': !newSkill.preferred}"
                                        (click)="updatePreferred(newSkill)" tooltip="Preferred" container="true">
                                    </span>
                                    <span *ngIf="!newSkill.mandatory && !newSkill.preferred"
                                        class="cursor-pointer text-primary skill-nice-to-have-icon fa-thumbs-up pr-3 mr-1 pt-1"
                                        style="font-size: 16px;"
                                        [ngClass]="{'fas': newSkill.niceToHave, 'far': !newSkill.niceToHave}"
                                        (click)="updateNiceToHave(newSkill)" tooltip="Nice to have" container="true">
                                    </span>
                                    <span style="font-size: 16px;" class="cursor-pointer text-danger fa-heart pr-3 mr-1 pt-1"
                                        [ngClass]="{'fas': newSkill.favorite, 'far': !newSkill.favorite}"
                                        (click)="newSkill.favorite = !newSkill.favorite" tooltip="Favorite" container="true">
                                    </span>
                                    <span style="font-size: 18px; top: 2px" class="fas fa-times text-danger cursor-pointer position-relative"
                                        tooltip="Clear entry" (click)="clearEntry()"></span>
                                </span>
                            </div>
                            <div class="col-xs-1">
                                <button class="btn btn-primary position-relative px-3 py-2"
                                    (click)="addNewSkill(newSkill.displayName, 'btn', '', '')" style="bottom: 6px;">Add</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="col-xs-12 mt-3">
                <div class="col-xs-12 my-2">
                    <!-- Education -->
                    <div class="col-xs-12 col-lg-6 px-0">
                        <div class="skill-category-container mr-2 py-4 px-3">
                            <app-add-edit-candidate-skill [skills]="requisitionSkills.educations" skillType="education" context="job"
                            mode="edit" [allExistingFieldOfStudy]="allExistingFieldOfStudy" [allExistingDegrees]="allExistingDegrees" [isViewOnly]="isViewOnly"></app-add-edit-candidate-skill>
                        </div>
                    </div>
                    <!-- Experience -->
                    <div class="col-xs-12 col-lg-6 px-0">
                        <div class="skill-category-container ml-3 py-4 px-3">
                            <app-add-edit-candidate-skill [skills]="requisitionSkills.experiences" [allExistingSkills]="allExistingSkills"
                                (saveCallback)="skillsSaveCallback($event)" skillType="experience" context="job"
                                mode="edit" [allExistingRoles]="allExistingRoles" [allExistingFieldOfStudy]="allExistingFieldOfStudy" [timePeriods]="timePeriods"
                                [isViewOnly]="isViewOnly"></app-add-edit-candidate-skill>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 my-3">
                    <!-- Technical skills -->
                    <div class="col-xs-12 col-lg-6 px-0">
                        <div class="skill-category-container mr-2 py-4 px-3">
                            <app-add-edit-candidate-skill [skills]="requisitionSkills.technicalSkills" [allExistingSkills]="allExistingSkills"
                                (saveCallback)="skillsSaveCallback($event)" skillType="technical" context="job"
                                mode="edit" [allExistingJDActionWords]="allExistingJDActionWords"
                                [timePeriods]="skillsTimePeriods" (setOtherSkillsCallback)="addSkillsToOther($event, 'technicalSkills')"
                                (setSkillsAsPerCategoryCallback)="addSkillsAsPerCategory($event, 'technicalSkills')"
                                [isViewOnly]="isViewOnly"></app-add-edit-candidate-skill>
                        </div>
                    </div>
                    <!-- Operational Skills -->
                    <div class="col-xs-12 col-lg-6 px-0">
                        <div class="skill-category-container ml-3 py-4 px-3">
                            <app-add-edit-candidate-skill [skills]="requisitionSkills.operationalSkills"
                                [allExistingSkills]="allExistingSkills" (saveCallback)="skillsSaveCallback($event)"
                                skillType="operational" context="job" mode="edit"
                                [allExistingJDActionWords]="allExistingJDActionWords"
                                [timePeriods]="skillsTimePeriods" (setOtherSkillsCallback)="addSkillsToOther($event, 'operationalSkills')"
                                (setSkillsAsPerCategoryCallback)="addSkillsAsPerCategory($event, 'operationalSkills')"
                                [isViewOnly]="isViewOnly"></app-add-edit-candidate-skill>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 my-2">
                    <!-- Soft Skills -->
                    <div class="col-xs-12 col-lg-6 px-0">
                        <div class="skill-category-container mr-2 py-4 px-3">
                            <app-add-edit-candidate-skill [skills]="requisitionSkills.softSkills" [allExistingSkills]="allExistingSkills"
                                (saveCallback)="skillsSaveCallback($event)" skillType="soft" context="job"
                                mode="edit" [allExistingJDActionWords]="allExistingJDActionWords"
                                [timePeriods]="skillsTimePeriods" (setOtherSkillsCallback)="addSkillsToOther($event, 'softSkills')"
                                (setSkillsAsPerCategoryCallback)="addSkillsAsPerCategory($event, 'softSkills')"
                                [isViewOnly]="isViewOnly"></app-add-edit-candidate-skill>
                        </div>
                    </div>
        
                    <!-- Other Skills -->
                    <div class="col-xs-12 col-lg-6 px-0">
                        <div class="skill-category-container ml-3 py-4 px-3">
                            <app-add-edit-candidate-skill [skills]="requisitionSkills.otherSkills" [allExistingSkills]="allExistingSkills"
                                (saveCallback)="skillsSaveCallback($event)" skillType="other" context="job"
                                mode="edit" [allExistingJDActionWords]="allExistingJDActionWords"
                                [timePeriods]="skillsTimePeriods" (setSkillsAsPerCategoryCallback)="addSkillsAsPerCategory($event, 'otherSkills')"
                                [isViewOnly]="isViewOnly"></app-add-edit-candidate-skill>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 my-3">
                    <!-- Certifications -->
                    <div class="col-xs-12 col-lg-6 px-0">
                        <div class="skill-category-container mr-2 py-4 px-3">
                            <app-add-edit-candidate-skill 
                                [skills]="requisitionSkills.certifications" 
                                skillType="certification" 
                                context="job"
                                mode="edit" 
                                [allExistingCertificates]="allExistingCertificates"
                                [isViewOnly]="isViewOnly"
                                >
                            </app-add-edit-candidate-skill>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-xs-12 pt-4" *ngIf="!isLoading && !isSharedReq">
    <div class="main-box clearfix col-xs-12">
        <div class="main-box-body clearfix col-xs-12 px-4">
            <h2 class="requisition-description-heading my-4 pb-1 font-bold">Benefits</h2>
            <!-- Benefits note -->
            <div class="form-group mb-0">
                <app-ngx-editor [placeholder]="'Enter text here...'" [spellcheck]="true" [(ngModel)]="requisitionSkills.benefitsNote"
                    [config]="editorConfig">
                </app-ngx-editor>
                <!-- <div class="error error-gap" *ngIf="!benefitsNotes">
                <small class=""><em>*</em>
                    External Note is required
                </small>
            </div> -->
            </div>
        </div>
    </div>
</div>

<div class="main-box-body clearfix col-lg-12" *ngIf="isLoading">
    <div class="text-center" style="font-size: 20px;">
        <span class="pr-2">Loading</span>
        <span class="fa fa-spinner fa-spin"></span>
    </div>
</div>
