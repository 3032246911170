import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { NewRequisitionSkills } from 'src/app/core/models/newRequistionSkills';
import { RequisitionSkill } from 'src/app/core/models/requisitionSkill';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { map, switchMap } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { ManageSkillsService } from 'src/app/core/services/manage-skills.service';

@Component({
  selector: 'app-requisition-skills',
  templateUrl: './requisition-skills.component.html',
  styleUrls: ['./requisition-skills.component.css']
})
export class RequisitionSkillsComponent implements OnInit {

  @Input() currentJobStatus;
  @Input() isUploadRequisition;
  @Input() jobTitle;
  @Input() jobLocation;
  @Input() allExistingSkills;
  @Input() allExistingDegrees;
  @Input() allExistingFieldOfStudy;
  @Input() allExistingRoles;
  @Input() allUpdatedRoles;
  @Input() allExistingCertificates;
  @Input() allExistingJDActionWords;
  @Input() timePeriods;
  @Input() skillsTimePeriods;
  @Input() isSharedReq;
  @Input() profileCriteriaInfo;
  @Output() showJobProfileCallback =  new EventEmitter<any>();
  @Output() updateLocationCallback = new EventEmitter<any>();
  @Output() updateJobTitleCallback = new EventEmitter<any>();

  public allSkillsObservable:  Observable<any[]>;
  requisitionSkills: any;
  benefitsNotes: any = '';
  editorConfig: any;
  addAllSkills: any = 'education';
  newRequisitionSkill: any = {};
  allEducationsList: any = [];
  educationDepartmentList: any = [];
  experiencesList: any = [];
  certificationsList: any = [];
  ranges: any = [];
  // timePeriods: any = [];
  // skillsTimePeriods: any = [];
  searchSkills: any = [];
  allUpdatedJDActionWords: any = [];
  newSkill: any = new RequisitionSkill('');
  newSkillSearchText: any = '';

  isLoading: boolean = true;
  isViewOnly: boolean = false;

  constructor(
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private skillsService: ManageSkillsService
  ) { 
  }

  ngOnInit() {
    this.isViewOnly = this.isSharedReq ? true : false;
    this.editorConfig = {
      toolbar: [
        ['bold', 'italic', 'h1', 'h2', 'h3'],
        ['underline', 'strikeThrough'],
        ['fontSize'],
        ['orderedList', 'unorderedList'],
        ['undo', 'redo'],
        ['paragraph'],
        ['blockquote'],
        ['removeFormat']
      ]
    };
    this.requisitionSkills = this.profileCriteriaInfo ? this.profileCriteriaInfo : new NewRequisitionSkills();
    this.setAllRequisitionSkills();
    this.isLoading = false;
    this.setSkillsObservable();
  }

  escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
  }

  setAllRequisitionSkills() {
    
    this.ranges = [
      { name: "", value: "" },
      { name: "Atleast", value: "atleast" },
      { name: "Min", value: "min" },
      { name: "Max", value: "max" }
    ];
    this.newRequisitionSkill = new RequisitionSkill('');
    const skillCategories = [
      'educations',
      'experiences',
      'technicalSkills',
      'softSkills',
      'otherSkills',
      'operationalSkills',
      'certifications'
    ];
    if(!this.profileCriteriaInfo) {
      skillCategories.forEach(category => {
        this.requisitionSkills[category].push(new RequisitionSkill(''));
      });
    } else {
      skillCategories.forEach(category => {
        if(!this.requisitionSkills[category] || this.requisitionSkills[category].length === 0) {
          this.requisitionSkills[category] = [];
          this.requisitionSkills[category].push(new RequisitionSkill(''));
        }
      });
    }
    // Set Skills
    this.allExistingSkills.forEach(skill => {
        skill.competency = skill.skill;
    });
    // Set Degrees
    let equivalentDegreeList = [];
    this.allExistingDegrees.forEach(degree => {
      // let tempEquivalentDegree = JSON.parse(JSON.stringify(degree));
      // tempEquivalentDegree.competency = degree.degreeName + " or equivalent experience";
      // tempEquivalentDegree.name = degree.degreeName + " or equivalent experience";
      // tempEquivalentDegree.degreeName = degree.degreeName + " or equivalent experience";
      // equivalentDegreeList.push(tempEquivalentDegree);
      let tempInDegree = JSON.parse(JSON.stringify(degree));
      tempInDegree.competency = degree.degreeName + " in ";
      tempInDegree.name = degree.degreeName + " in ";
      tempInDegree.degreeName = degree.degreeName;
      equivalentDegreeList.push(tempInDegree);
      degree.competency = degree.degreeName;
      degree.name = degree.degreeName;
    });
    this.allExistingDegrees = [...this.allExistingDegrees, ...equivalentDegreeList];
    // Set Roles
    this.allExistingRoles.forEach(role => {
      role.competency = role.role;
    });
    // Set Certificates
    this.allExistingCertificates.forEach(certificate => {
      certificate.competency = certificate.name;
      certificate.inName = certificate.group + " in "
    });
    // Set Jd Action Words
    this.allExistingJDActionWords.forEach(jdAction => {
      if (jdAction.jobDescriptionLanguageDropdown && jdAction.jobDescriptionLanguageDropdown.length > 0) {
        jdAction.jobDescriptionLanguageDropdown.forEach(actionWord => {
          let actionWordTemp = { name: actionWord, jdAction: jdAction };
          this.allUpdatedJDActionWords.push(actionWordTemp);
        });
      }
    });
  }

  skillsSaveCallback(event) {
    if (event && event.length > 0) {
      this.allExistingSkills = event;
    }
  }

  onBenefitsNoteChanged() {

  }

  revertToJobDescriptionFile() {
    let message = "<p>Are you sure?</p><p>All your changes will be lost and will be put back to the uploaded Job Description.</p>";
    this.alertsAndNotificationsService.showConfirm("Revert to Job description file", message, "warning", true, () => {
      // Api for reverting changes to last saved version
      this.alertsAndNotificationsService.showBannerMessage('Changes reverted to uploaded Job Description successfully', 'success');
    }, () => {
      //do nothing  
    });
  }

  revertToSavedChanges() {
    let message = "<p>Are you sure?</p><p>All your changes will be lost and will be put back to the previously saved information.</p>";
    this.alertsAndNotificationsService.showConfirm("Revert to Saved changes", message, "warning", true, () => {
      // Api for reverting changes to last saved version
      this.alertsAndNotificationsService.showBannerMessage('Changes reverted to saved information successfully', 'success');
    }, () => {
      //do nothing  
    });
  }

  revertToOriginalActivatedVersion() {
    let message = "<p>Are you sure?</p><p>Any changes done since the requisition was originally activated, including versions saved after that and any current changes will be lost. You be returned to the original criteria at the time the Requisition was <b>Activated.</b></p><p>This change cannot be <b>undone.</b></p>";
    this.alertsAndNotificationsService.showConfirm("Revert to Original Activated Version", message, "warning", true, () => {
      // Api for reverting changes to last saved version
      this.alertsAndNotificationsService.showBannerMessage('Changes reverted to the original criteria at the time the Requisition was <b>Activated.</b> successfully', 'success');
    }, () => {
      //do nothing  
    });
  }

  onAllSkillsChanges() {
    this.newRequisitionSkill = new RequisitionSkill('');
  }

  showJobProfile() {
    if(this.showJobProfileCallback) {
      this.showJobProfileCallback.emit();
    }
  }

  updateLocation() {
    if(this.updateLocationCallback) {
      this.updateLocationCallback.emit();
    }
  }

  addSkillsToOther(event, type) {
    if (event) {
      this.requisitionSkills.otherSkills.unshift(event);
      let title = "Skill Moved";
      let message = `<b>'${event.skill.skill}'</b> has been moved under <b>'Other'</b>`;
      this.alertsAndNotificationsService.showAlert(title, message, 'warning');
    }
  }

  addSkillsAsPerCategory(event, type) {
    let title = "Skill Moved";
    let categoryHeading = event.skill.category.charAt(0).toUpperCase() + event.skill.category.substring(1).toLowerCase();
    let message = `<b>'${event.skill.skill}'</b> is an <b>'${categoryHeading}'</b> Skill. It has been moved under <b>'${(event.skill.category.toLowerCase() == 'other') ? 'Other' : (categoryHeading + ' Skills')}'</b>`;
    if (event) {
      switch (event.skill.category.toLowerCase()) {
        case 'technical':
          this.requisitionSkills.technicalSkills.unshift(event);
          break;
        case 'operational':
          this.requisitionSkills.operationalSkills.unshift(event);
          break;
        case 'soft':
          this.requisitionSkills.softSkills.unshift(event);
          break;
        case 'other':
          this.requisitionSkills.otherSkills.unshift(event);
          break;
        default:
          this.requisitionSkills.otherSkills.unshift(event);
          break;
      }
      this.alertsAndNotificationsService.showAlert(title, message, 'warning');
    }
  }

  onJobTitleChange() {
    if (this.updateJobTitleCallback) {
      this.updateJobTitleCallback.emit(this.jobTitle);
    }
  }

  onRequisitionSkillSelect(e: TypeaheadMatch, skill, inputElement: HTMLInputElement): void {
    this.newSkill = e.item;
    this.newSkill.favorite = skill.favorite ? skill.favorite : false;
    this.newSkill.mandatory = skill.mandatory ? skill.mandatory : false;
    this.newSkill.niceToHave = skill.niceToHave ? skill.niceToHave : false;
    this.newSkill.preferred = skill.preferred ? skill.preferred : false;
    this.newSkill.priority = skill.priority ? skill.priority : false;
  }

  onSearchSkillChange(searchSkillText: string): void {
    this.newSkillSearchText = searchSkillText;
  }

  updateMandatory(skill) {
    skill.mandatory = !skill.mandatory;
    if (skill.mandatory) {
      skill.niceToHave = false;
      skill.preferred = false;
    }
  }

  updateNiceToHave(skill) {
    skill.niceToHave = !skill.niceToHave;
    if (skill.niceToHave) {
      skill.mandatory = false;
      skill.preferred = false;
    }
  }

  updatePreferred(skill) {
    skill.preferred = !skill.preferred;
    if (skill.preferred) {
      skill.mandatory = false;
      skill.niceToHave = false;
    }
  }

  addNewSkillToList(skills: any[], newSkill: any): any[] {
    const updatedSkills = [...skills];
  
    if (updatedSkills.length > 0) {
      const lastSkill = updatedSkills[updatedSkills.length - 1];
  
      if (!lastSkill.displayName || lastSkill.displayName.trim() === '') {
        updatedSkills.pop(); // remove the last empty skill
      }
    }
  
    updatedSkills.push(newSkill);
    return updatedSkills;
  }

  addNewSkill(inputText: string, type: string, inputEvent: any, skill: any) {
    if (this.newSkill && this.newSkill.displayName && this.newSkill.displayName != '') {
      const item = { ...this.newSkill };
      delete item.experience;

      const skills = this.requisitionSkills;
      const typeKey = this.newSkill.skillType;

      if (typeKey === 'education' || typeKey === 'experience' || typeKey === 'certification') {
        const key = typeKey + 's'; // plural keys like 'educations', 'experiences'
        skills[key] = skills[key] && skills[key].length ? this.addNewSkillToList(skills[key], this.newSkill) : [this.newSkill];
      } else if (this.newSkill.skill && this.newSkill.skill.category) {
        let category = this.newSkill.skill.category;
        let skillKey = '';

        if (category === 'Technical') skillKey = 'technicalSkills';
        else if (category === 'Operational') skillKey = 'operationalSkills';
        else if (category === 'Soft') skillKey = 'softSkills';
        else if (category === 'Other') skillKey = 'otherSkills';

        if (skillKey) {
          skills[skillKey] = skills[skillKey] && skills[skillKey].length
            ? this.addNewSkillToList(skills[skillKey], item)
            : [item];
        }
      } else {
        // Default to otherSkills if category is not found
        skills.otherSkills = skills.otherSkills && skills.otherSkills.length
          ? this.addNewSkillToList(skills.otherSkills, item)
          : [item];
      }

      this.newSkill = new RequisitionSkill('');
    } else {
      this.alertsAndNotificationsService.showAlert('Empty Entry', 'Please enter details to add', 'warning');
    }
  }

  setSkillsObservable() {
    this.allSkillsObservable = Observable.create((observer: any) => {
      observer.next(this.newSkillSearchText);
    }).pipe(
      switchMap((token: string) =>
        forkJoin([
          this.skillsService.getSkillsAsObservable(token, 'certification', this.allExistingCertificates),
          this.skillsService.getSkillsAsObservable(token, 'education', {
            degrees: this.allExistingDegrees,
            fieldOfStudy: this.allExistingFieldOfStudy
          }),
          this.skillsService.getSkillsAsObservable(token, 'experience', {
            fieldOfStudy: this.allExistingFieldOfStudy,
            roles: this.allExistingRoles,
            timePeriods: this.timePeriods
          }),
          this.skillsService.getSkillsAsObservable(token, 'skills', {
            skills: this.allExistingSkills,
            timePeriods: this.timePeriods
          })
        ])
      ),
      map(([certs, education, experience, skills]) => {
        return [...certs, ...education, ...experience, ...skills];
      })
    );
  }

  clearEntry() {
    this.newSkill = new RequisitionSkill('');
  }

}

