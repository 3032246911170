import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ManageSkillsService } from 'src/app/core/services/manage-skills.service';
import { RequisitionSkill } from 'src/app/core/models/requisitionSkill';
declare var bootbox: any;

@Component({
    selector: 'app-add-edit-candidate-skill',
    templateUrl: './add-edit-candidate-skill.component.html',
    styleUrls: ['./add-edit-candidate-skill.component.css']
})
export class AddEditCandidateSkillComponent implements OnInit {

    @Input() userId;
    @Input() skills;
    @Input() skillType;
    @Input() context;
    @Input() mode;
    @Input() currentSkills;
    @Input() originalSkills;
    @Input() allExistingSkills;
    @Input() allExistingDegrees;
    @Input() allExistingFieldOfStudy;
    @Input() allExistingRoles;
    @Input() allExistingCertificates;
    @Input() allExistingJDActionWords;
    @Input() timePeriods;
    @Input() isViewOnly;
    @Output() saveCallback = new EventEmitter<any>();
    @Output() cancelCallback = new EventEmitter<any>();
    @Output() setOtherSkillsCallback = new EventEmitter<any>();
    @Output() setSkillsAsPerCategoryCallback = new EventEmitter<any>();

    skillHeading: any;
    skillsCopy: any;
    selected: any = undefined;
    searchResults: any = [];
    selectedSkillType: any;
    skillTypes: any = [];
    skillsTimePeriods: any = [];
    allSkills: any;
    skillsTypeAheadList: any = [];
    ranges: any = [];
    resetSkillsList: any = [];
    educationFieldOfStudy: any = [];
    experienceRoles: any = [];
    draggable: any;
    addNewSkill: any;
    addNewSkillObj: any;
    isEditSkills: boolean = false;
    viewSkillDetails: boolean = true;
    expandSkills: boolean;
    isRemoveSectionActive: boolean = false;

    searchCertificationText: any = '';
    searchCertificates: any;
    searchEducationText: any = '';
    searchEducations: any;
    searchExperienceText: any = '';
    searchExperiences: any;
    searchSkillText: any = '';
    searchSkills: any;
    sections: any = [[new RequisitionSkill('')]];
    public certificationsObservable: Observable<any[]>;
    public educationObservable: Observable<any[]>;
    public experienceObservable: Observable<any[]>;
    public skillsObservable:  Observable<any[]>;

    constructor(
        private alertsAndNotificationsService: AlertAndNotificationsService,
        private skillsService: ManageSkillsService
    ) {
    }

    ngOnChanges(changes) {
        if (changes && changes.allExistingSkills && !changes.allExistingSkills.firstChange && changes.allExistingSkills.currentValue && changes.allExistingSkills.previousValue) {
            if (changes.allExistingSkills.currentValue.length != changes.allExistingSkills.previousValue) {
                this.setAllExistingSkills(changes.allExistingSkills.currentValue);
            }
        }

        if (changes && changes.skills && !changes.skills.firstChange && changes.skills.currentValue && changes.skills.previousValue) {
            if (changes.allExistingSkills && (changes.allExistingSkills.currentValue.length != changes.allExistingSkills.previousValue)) {
                this.skillsCopy = this.skills.slice(0,);
            }
        }
    }

    ngOnInit() {
        this.skills = this.skills ? this.skills : [];
        this.skills.forEach(skill => {
            skill.range = "",
            skill.plusTo = ""
        });
        this.skillHeading = this._getSkillsHeading();
        this.skillsCopy = angular.copy(this.skills);
        // if (this.mode && this.mode === 'edit') {
        //     this.isEditSkills = true;
        // }
        this._setAllSkills();
        this.selectedSkillType = "education";
        this.skillTypes = [
            { name: "Education", value: "education" },
            { name: "Experience", value: "experience" },
            { name: "Technical Skills", value: "technical" },
            { name: "Operational Skills", value: "operational" },
            { name: "Soft Skills", value: "soft" },
            { name: "Other", value: "other" },
            { name: "Certifications", value: "certifications" },
        ];
        let skills = {
            education: [],
            experience: [],
            technical: [],
            operational: [],
            soft: [],
            other: [],
            certifications: []
        };
        this.currentSkills = angular.copy(skills);
        this.originalSkills = angular.copy(skills);

        this._massageSkillsData();
        this.ranges = [
            { name: "", value: "" },
            { name: "Atleast", value: "atleast" },
            { name: "Min", value: "min" },
            { name: "Max", value: "max" }
        ];
        this.resetSkillsList = [...this.skills];
        this.setSkillsObservable();
        this.isEditSkills = true; // New Change
    }

    setSkillsObservable() {
        this.certificationsObservable = this.skillsService.createSkillObservable(
            'certification',
            () => this.searchCertificationText,
            this.allExistingCertificates
        );

        this.educationObservable = this.skillsService.createSkillObservable(
            'education',
            () => this.searchEducationText,
            {
                degrees: this.allExistingDegrees,
                fieldOfStudy: this.allExistingFieldOfStudy
            }
        );

        this.experienceObservable = this.skillsService.createSkillObservable(
            'experience',
            () => this.searchExperienceText,
            {
                fieldOfStudy: this.allExistingFieldOfStudy,
                roles: this.allExistingRoles,
                timePeriods: this.timePeriods
            }
        );

        this.skillsObservable = this.skillsService.createSkillObservable(
            'skills',
            () => this.searchSkillText,
            {
                skills: this.allExistingSkills,
                timePeriods: this.timePeriods
            }
        );
    }

    onSearchCertificationChange(searchCertificationText: string): void {
        this.searchCertificationText = searchCertificationText;
    }

    onSearchEducationChange(searchEducationText: string): void {
        this.searchEducationText = searchEducationText;
    }

    onSearchExperienceChange(searchExperienceText: string): void {
        this.searchExperienceText = searchExperienceText;
    }

    onSearchSkillChange(searchSkillText: string): void {
        this.searchSkillText = searchSkillText;
    }

    _setTypeAheadList(type) {
        switch (type) {
            case 'skills':
                return this.allSkills.allExistingSkills;
            default:
                return this.allSkills.other;
        }
    }

    _getSkillsHeading() {
        switch (this.skillType) {
            case "education":
                return "Education";
            case "experience":
                return "Experience";
            case "technical":
                return "Technical Skills";
            case "operational":
                return "Operational Skills";
            case "soft":
                return "Soft Skills";
            case "other":
                return "Other";
            case "certification":
                return "Certifications"
            default:
                return "Technical Skills";
        }
    }

    _setAllSkills() {
        this.allSkills = {
            education: [],
            department: [],
            experience: [],
            certifications: []
        };

        if (this.allExistingSkills && this.allExistingSkills.length > 0) {
            this.allExistingSkills.forEach(skill => {
                skill.competency = skill.skill;
            });
            this.setAllExistingSkills(this.allExistingSkills);
        } else {
            this.setAllExistingSkills(null);
        }
    }


    _massageSkillsData() {
        angular.forEach(this.skillTypes, (type, key) => {
            angular.forEach(this.allSkills[type.value], (skill, key) => {
                this._setSkillDisplayValue(skill);
            });
            angular.forEach(this.skills[type.value], (skill, key) => {
                this._setSkillDisplayValue(skill);
            });
            angular.forEach(this.currentSkills[type.value], (skill, key) => {
                this._setSkillDisplayValue(skill);
            });
            angular.forEach(this.originalSkills[type.value], (skill, key) => {
                this._setSkillDisplayValue(skill);
            });
        });
    }

    _setSkillDisplayValue(skill) {
        var skillDisplayValue = "";
        skillDisplayValue = skillDisplayValue + skill.name;
        skill.skillDisplayValue = skillDisplayValue;
    }

    convertMonthsToYearsText(periodValue, skill, displayName) {
        const wordToNumber = {
            "one": 1,
            "two": 2,
            "three": 3,
            "four": 4,
            "five": 5,
            "six": 6,
            "seven": 7,
            "eight": 8,
            "nine": 9,
            "ten": 10,
            "eleven": 11,
            "twelve": 12
        };
    
        // Regex to capture numeric or word-based months, with optional 'plus'/'+' or "At least/Minimum/Maximum"
        let monthsPattern = /(?:Atleast|Minimum|Maximum)?\s*(\d+|one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve)\s*(?:\+|plus)?\s*month(?:s)?/i;
    
        let match = displayName.match(monthsPattern);
        
        if (match) {
            let monthsString = match[1].toLowerCase();
            let months = isNaN(monthsString) ? wordToNumber[monthsString] : parseInt(monthsString);  // Convert word to number or use the numeric value
    
            let prefix = '';
            let suffix = '';
            
            // Check for "Atleast", "Minimum", "Maximum" and append them to the prefix
            if (/Atleast/i.test(displayName)) {
                prefix = 'Atleast ';
            } else if (/Minimum/i.test(displayName)) {
                prefix = 'Minimum ';
            } else if (/Maximum/i.test(displayName)) {
                prefix = 'Maximum ';
            }
    
            // Adjust for "plus" or "+" indicating additional months and update suffix
            if (match[0].includes('plus') || match[0].includes('+')) {
                suffix = ' +'; // Add the plus suffix
            }
    
            let years = (months / 12).toFixed(1);  // Convert months to years and round to 1 decimal place
    
            // Extract the skill from the displayName by removing the matched time period
            let skillName = displayName.replace(match[0], '').trim();
    
            return `${prefix}${years}${suffix} years ${skillName}`;
        } else {
            return '';
        }
    }

    onSkillSelect(e: TypeaheadMatch): void {
        this._addSkill(e);
        this.addNewSkillObj = e.item;
    }

    _addSkill(selectedSkill) {
        if (selectedSkill.id !== undefined) {
            this._setSkillDisplayValue(selectedSkill);
            this.selected = undefined;
        }
    }

    setFocusToNextElement(type, inputEvent, isLastElement) {
        let baseId;
        let currentIndex;
        if(type === 'onAddSkill') {
            baseId = (inputEvent === 'skillsInput') ? (inputEvent + this.skillType) : inputEvent;
            currentIndex = this.skills.length - 1;
        } else {
            inputEvent.blur();
            baseId = inputEvent.id.split('-')[0];
            currentIndex = parseInt(inputEvent.id.split('-')[1]);
        }
        let nextIndex = currentIndex + 1;
        let nextId = `${baseId}-${!isLastElement ? nextIndex : (this.skills.length - 1)}`;
        let nextElement = document.getElementById(nextId);
        nextElement.focus();
    }

    checkAndAddNewSkill(type, inputEvent, inputText, skill) {
        let checkIfEmptyInputFieldExists = this.skills.filter(skill => (!skill.displayName || skill.displayName == ''));
        if(checkIfEmptyInputFieldExists && checkIfEmptyInputFieldExists.length > 0) {
            setTimeout(() => {
                this.setFocusToNextElement(type, inputEvent, true);
            }, 100);
            if(type === 'onAddSkill') {
                this.displayEmptySkillMessage();
            }
        } else {
            if(this.checkIfSkillExists(skill, inputText)) {
                this.onAddSkill(type, skill, inputText, inputEvent);
            } else {
                if (type === 'onAddSkill') {
                    this.skills.push(new RequisitionSkill(''));
                } else {
                    this.displayEmptySkillMessage();
                }
            }
            
        }
    };

    checkIfSkillExists(skill, inputText) {
        if(this.skillType != 'education' && this.skillType != 'certification') {
            let timePeriodObj = this.timePeriods.filter(timePeriod => inputText.includes(timePeriod.name)).sort((a, b) => b.name.length - a.name.length)[0];
            let existingSkillName = timePeriodObj ? inputText.replace(timePeriodObj.name, '').trim() : inputText;
            return (existingSkillName && existingSkillName != '') ? true : false;
        } else {
            return true;
        }
    }

    newSkill(inputText: string, type: string, inputEvent: any, skill) {
        if (inputText && inputText !== '') {
            if (skill.typeAheadSelected && (skill.oldDisplaySkillName != skill.displayName)) {
                this.expandSkills = true;
                skill.typeAheadSelected = false;
                skill.oldDisplaySkillName = skill.displayName.slice();
            } else {
                this.expandSkills = true;
                this.checkAndAddNewSkill(type, inputEvent, inputText, skill);
            }
        } else {
            if (type === 'onEnter') {
                this.displayEmptySkillMessage();
            } else {
                this.expandSkills = true; 
                this.checkAndAddNewSkill(type, inputEvent, inputText, skill);
            }
        }
    }    

    displayEmptySkillMessage() {
        if (this.skillType === 'technical' || this.skillType === 'operational' || this.skillType === 'soft' || this.skillType === 'other') {
            this.alertsAndNotificationsService.showAlert('Skill Empty', "Enter Skill to Add", 'warning');
        } else {
            const title = this.skillType === 'education' ? 'Education' : this.skillType === 'experience' ? 'Experience' : 'Certification';
            this.alertsAndNotificationsService.showAlert(`${title} Empty`, `Enter ${title} to Add`, 'warning');
        }
    }

    _setNewItem(newItem: any) {
        return newItem && newItem.id ? {
            ...newItem,
            competency: newItem.skill || newItem.name
        } : {
            id: null,
            name: this.addNewSkill,
            range: '',
            competency: this.addNewSkill,
            mandatory: false,
            favorite: false,
            experience: { years: 0, months: 0 }
        };
    }

    setSkillsCopy(skills) {
        this.skillsCopy = skills.slice(0,);
    }

    onAddSkill(type, skill, inputText, inputEvent) {
        if (this.skillType === 'technical' || this.skillType === 'operational' || this.skillType === 'soft' || this.skillType === 'other') {
            let timePeriodObj = this.timePeriods.filter(timePeriod => (inputText.length > timePeriod.name.length) && (inputText.includes(timePeriod.name))).sort((a, b) => b.name.length - a.name.length)[0];
            // Check if input text has any of existing skills
            let existingSkillName = timePeriodObj ? inputText.replace(timePeriodObj.name, '').trim() : inputText;
            let existingSkill = this.allExistingSkills.filter(skill => 
                existingSkillName.toLowerCase() === skill.skill.toLowerCase()
            );

            if(!existingSkill || existingSkill.length === 0) {
                let newSkill = this.skills.filter(skill => skill.displayName === inputText)[0];
                newSkill.skill = {skill: existingSkillName};
                this.skills.push(new RequisitionSkill(''));
                setTimeout(() => {
                    this.setFocusToNextElement(type, inputEvent, false);
                }, 100);
            } else {
                if(existingSkill[0].category.toLowerCase() != this.skillType) {
                    let newSkill = this.skills.filter(skill => skill.displayName === inputText);
                    this.skills.push(new RequisitionSkill(''));
                    setTimeout(() => {
                        this.setFocusToNextElement(type, inputEvent, false);
                    }, 100);
                } else {
                    this.skills.push(new RequisitionSkill(''));
                    setTimeout(() => {
                        this.setFocusToNextElement(type, inputEvent, false);
                    }, 100);
                }
            }
            if(this.skills.length === 0) {
                this.skills.push(new RequisitionSkill(''));
            }
        } else {
            this.skills.push(new RequisitionSkill(''));
        }
    }

    onSkillUpdate(skillToUpdate) {
        this._setSkillDisplayValue(skillToUpdate);
        var skillIndex = _.findIndex(this.skills[this.selectedSkillType], (skill: any) => {
            return skillToUpdate.id === skill.id && skillToUpdate.skillDisplayValue === skill.skillDisplayValue;
        });
        this.skills[this.selectedSkillType].splice(skillIndex, 1, skillToUpdate);
    }

    onDeleteSkill(skill) {
        this._deleteSkill(skill);
        if (this.allSkills.allExistingSkills) {
            this.allSkills.allExistingSkills = this.allSkills.allExistingSkills.push(skill);
        }
    }

    _deleteSkill(skillToDelete) {
        _.remove(this.skills[this.selectedSkillType], (skill: any) => {
            return skillToDelete.id == skill.id && skillToDelete.skillDisplayValue == skill.skillDisplayValue;
        });
    }

    onResetSkillsClick(resetType) {
        var message = "";
        if (resetType == "previous") {
            message = "Going back to Requisition Detail of version saved previously. Are you sure to continue?";
        } else if (resetType == 'original') {
            message = "Going back to Requisition Detail of version saved when Requisition was ‘Activated’. Are you sure to continue?";
        }
        bootbox.confirm({
            closeButton: false,
            title: "<div class='alert alert-warning' style='margin-bottom: 0px;'><i class='fas fa-exclamation-triangle fa-fw fa-lg'></i><strong>Warning!</strong></div>",
            message: message,
            buttons: {
                confirm: {
                    label: 'Yes',
                    className: 'btn-success'
                },
                cancel: {
                    label: 'Cancel',
                    className: 'btn-danger'
                }
            },
            callback: (result) => {
                if (result) {
                    this._resetSkill(resetType);
                }
            }
        });
    }

    _resetSkill(resetType) {
        if (resetType == "previous") {
            this.skills = angular.copy(this.currentSkills);
        } else if (resetType == "original") {
            this.skills = angular.copy(this.originalSkills);
        }
    }

    toggleDetailsView() {
        this.viewSkillDetails = !this.viewSkillDetails;
    }

    enter() {
        console.log(this.selected);
        var selectedSkill = { id: 10, name: this.selected, mandatory: false, favorite: false, experience: { years: 0, months: 0 } };
        this._setSkillDisplayValue(selectedSkill);
        this.skills[this.selectedSkillType].push(selectedSkill);
        this.selected = undefined;
    }

    setAllExistingSkills(allSkills) {
        let existingSkillsIds = this.skills ? this.skills.map(skill => { return skill.id }) : [];
        if (this.allExistingSkills) {
            this.allExistingSkills = this.allExistingSkills.filter(skill => {
                if (!existingSkillsIds.includes(skill.id)) {
                    return true;
                }
            });
        }
        if (allSkills) {
            this.allSkills.allExistingSkills = [...this.allExistingSkills];
        } else {
            this.allSkills.allExistingSkills = [];
        }
    }

    editSkills() {
        this.isEditSkills = !this.isEditSkills;
        if (this.skillType === 'certification') {
            this.skills = this.skills.filter(skill => skill.displayName && skill.displayName != '');
            this.skills.forEach(skill => {
                skill = this.setCertificate(skill);
            });
        } else if (this.skillType === 'education') {
            this.skills = this.skills.filter(skill => skill.displayName && skill.displayName != '');
            this.skills.forEach(skill => {
                skill = this.setEducation(skill);
            });
        } else if (this.skillType === 'experience') {
            this.skills = this.skills.filter(skill => skill.displayName && skill.displayName != '');
            this.skills.forEach(skill => {
                skill = this.setExperience(skill);
            });
        } else {
            this.skills = this.skills.filter(skill => skill.displayName && skill.displayName != '');
            this.skills.forEach(skill => {
                skill = this.setSkills(skill);
            });
        }

        if (this.skillsCopy.length === this.skills.length) {
            this.resetSkillsList = [...this.skills];
        } else {
            const deletedSkills = this.skillsCopy.filter(element =>
                !this.skills.some(skill => (skill.id && skill.id === element.id) || (skill.skill === element.skill))
            );
            if (this.skillType != 'certification' && this.skillType != 'education' && this.skillType != 'experience') {
                this.allExistingSkills = [...this.allExistingSkills, ...deletedSkills];
            }
            this.resetSkillsList = [...this.skills];
        }

        if (this.skillType != 'certification' && this.skillType != 'education' && this.skillType != 'experience') {
            this.setAllExistingSkills(this.allExistingSkills);
        }

        if (this.saveCallback) {
            this.saveCallback.emit(this.allExistingSkills);
        }

    }

    deleteSkill(index) {
        this.skills.splice(index, 1);
    }

    onCandidateSkillUpdate(skillIndex, skillToUpdate, skillPriority) {
        if (skillPriority == 'mandatory' && skillToUpdate.favorite == true) {
            skillToUpdate.favorite = false;
        } else if (skillPriority == 'favorite' && skillToUpdate.mandatory == false) {
            skillToUpdate.favorite = true;
        }

        this.skills.splice(skillIndex, 1, skillToUpdate);
    }

    resetSkills() {
        this.skills = [...this.resetSkillsList];
        this.editSkills();
    }

    updateMandatory(skill) {
        skill.mandatory = !skill.mandatory;
        if (skill.mandatory) {
            skill.niceToHave = false;
            skill.preferred = false;
        }
    }

    updateNiceToHave(skill) {
        skill.niceToHave = !skill.niceToHave;
        if (skill.niceToHave) {
            skill.mandatory = false;
            skill.preferred = false;
        }
    }

    updatePreferred(skill) {
        skill.preferred = !skill.preferred;
        if (skill.preferred) {
            skill.mandatory = false;
            skill.niceToHave = false;
        }
    }

    onEditSkill() {
        this.isEditSkills = !this.isEditSkills;
        this.resetSkillsList = [...this.skills];
    }

    onRequisitionSkillSelect(e: TypeaheadMatch, skill, inputElement: HTMLInputElement): void {
        skill.typeAheadSelected = true;
        skill.displayName = e.item.displayName ? e.item.displayName : e.item.name;
        if (this.skillType === 'education') {
            skill.degreeName = e.item.degreeName ? e.item.degreeName : "";
            skill.fieldOfStudy = e.item.fieldOfStudy ? e.item.fieldOfStudy : "";
        } else if (this.skillType === 'certification') {
            skill.certificationGroup = e.item.certificationGroup ? e.item.certificationGroup : "";
            skill.certificationSkill = e.item.certificationSkill ? e.item.certificationSkill : "";
        } else {
            skill.skill = e.item.skill ? e.item.skill : null;
            skill.range = e.item.range;
            skill.plusTo = e.item.plusTo;
            skill.minExperienceInMonths = e.item ? e.item.minExperienceInMonths : "";
            skill.maxExperienceInMonths = e.item ? e.item.maxExperienceInMonths : "";
            skill.experienceInMonths = e.item ? e.item.months : "";
            if(this.skillType === 'experience') {
                skill.competency = e.item.role;
                skill.role = e.item.role;
                skill.preposition = e.item.preposition;
            } else {
                skill.competency = e.item.skill ? e.item.skill.skill : null;
                skill.actionWord = e.item ? e.item.actionWord : null;
            }
        }
        
        setTimeout(() => {
            if (inputElement) {
                inputElement.focus(); // Refocus input
                const length = inputElement.value.length;
                inputElement.setSelectionRange(length, length); // Move cursor to end
            }
        }, 0);
    }

    setCertificate(certificate) {
        if (certificate.displayName.includes(" in ")) {
            let degreesAndFieldOfStudy = certificate.displayName.split(" in ");
            certificate.degreeName = degreesAndFieldOfStudy[0] ? degreesAndFieldOfStudy[0] : "";
            certificate.fieldOfStudy = degreesAndFieldOfStudy[1] ? degreesAndFieldOfStudy[1] : "";
        } else if (certificate.displayName.includes(" in")) {
            let degreesAndFieldOfStudy = certificate.displayName.split(" in");
            certificate.degreeName = degreesAndFieldOfStudy[0] ? degreesAndFieldOfStudy[0] : "";
            certificate.fieldOfStudy = "";
        } else {
            certificate.degreeName = certificate.displayName;
            certificate.fieldOfStudy = "";
        }
    }

    setEducation(education) {
        if (education.displayName.includes(" in ") && !education.displayName.includes("or equivalent experience")) {
            let degreesAndFieldOfStudy = education.displayName.split(" in ");
            education.degreeName = degreesAndFieldOfStudy[0] ? degreesAndFieldOfStudy[0] : "";
            education.fieldOfStudy = degreesAndFieldOfStudy[1] ? degreesAndFieldOfStudy[1] : "";
        } else if (education.displayName.includes(" in") && !education.displayName.includes("or equivalent experience")) {
            let degreesAndFieldOfStudy = education.displayName.split(" in");
            education.degreeName = degreesAndFieldOfStudy[0] ? degreesAndFieldOfStudy[0] : "";
            education.fieldOfStudy = "";
        } else {
            education.degreeName = education.displayName;
            education.fieldOfStudy = "";
        }
    }

    setExperience(experience) {
        let timePeriodObj = this.timePeriods.filter(timePeriod => (experience.displayName.length > timePeriod.name.length) && (experience.displayName.includes(timePeriod.name))).sort((a, b) => b.name.length - a.name.length)[0];
        let addMonthsText = (timePeriodObj &&  timePeriodObj.value.months > 0) ? this.convertMonthsToYearsText(timePeriodObj.value, '', experience.displayName) : '';
            experience.convertedDisplayName = addMonthsText;
        if (timePeriodObj) {
            let typeAheadValue = timePeriodObj.value;
            experience.minExperienceInMonths = typeAheadValue.minExperienceInMonths;
            experience.maxExperienceInMonths = typeAheadValue.maxExperienceInMonths;
            experience.range = typeAheadValue.range;
            experience.plusTo = typeAheadValue.plusTo;
            experience.competency = experience.displayName.replace(timePeriodObj, "");
            experience.title = experience.displayName.replace(timePeriodObj, "");
        } else {
            experience.competency = experience.displayName;
            experience.title = experience.displayName;
            experience.minExperienceInMonths = "";
            experience.maxExperienceInMonths = "";
            experience.range = "";
            experience.plusTo = "";
        }
    }

    setSkills(skill) {
        let timePeriod = this.timePeriods.filter(timePeriod => ((skill.displayName.length > timePeriod.length) && skill.displayName.includes(timePeriod)))[0];
        if (timePeriod) {
            let typeAheadValue = timePeriod.value;
            skill.minExperienceInMonths = typeAheadValue.minExperienceInMonths;
            skill.maxExperienceInMonths = typeAheadValue.maxExperienceInMonths;
            skill.range = typeAheadValue.range;
            skill.plusTo = typeAheadValue.plusTo;
            skill.competency = skill.displayName.replace(timePeriod, "");
            skill.jdActionWord = skill.jdActionWord;
        } else {
            skill.competency = skill.displayName;
            skill.jdActionWord = "";
            skill.minExperienceInMonths = ""
            skill.maxExperienceInMonths = ""
            skill.range = ""
            skill.plusTo = "";
        }
    }

    newSection() {
        this.sections.push([new RequisitionSkill('')]);
        this.isRemoveSectionActive = this.sections.length > 1;
    }

    removeSection(index) {
        this.sections.splice(index,1);
        this.isRemoveSectionActive = this.sections.length > 1;
    }

    changeSkillPriority(skill, priority) {
        skill.priority = priority;
    }
}
