import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericService } from './generic.service';
import { StateManagementStorageService } from './state-management-storage.service';
import { StorageService } from './storage.service';
import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManageSkillsService {

  skillType: any;
  allExistingCertificates: any = [];
  allExistingDegrees: any = [];
  allExistingFieldOfStudy: any = [];
  allExistingRoles: any = [];
  educationFieldOfStudy: any = [];
  experienceRoles: any = [];
  timePeriods: any = [];
  allExistingSkills: any = [];

  urlConstants: any = {
    ADD_UPDATE_MOVE_SKILL: 'api/manage/skills',
    GET_ALL_SKILLS: 'api/manage/skills',
    DELETE_SKILL: 'api/manage/skills',
    BULK_DELETE_SKILLS: 'api/manage/skills/bulkDelete',
    GET_ALL_CATEGORIES: 'api/manage/skills/category',
    SKILL_UPLOAD_URL: 'api/manage/skills/upload',
    DOWNLOAD_SKILLS_URL: 'api/manage/skills/download',
    DOWNLOAD_UPLOADED_FILE: 'api/manage/skills/file',
    GET_ALL_SKILL_SORT_CATEGORIES: '../../../assets/app-content/jsonobjects/skillsSort.json',
    GET_ALL_ACTIVITIES: 'api/manage/skills/activity',
    UPLOAD_REQUISITION_ENTITIES_DEGREES: 'api/manage/degrees/upload',
    DOWNLOAD_UPLOADED_REQUISITION_ENTITIES_DEGREES: 'api/manage/degrees/file',
    GET_REQUISITION_ENTITIES_DEGREES: 'api/manage/degrees',
    DOWNLOAD_REQUISITION_ENTITIES_DEGREES: 'api/manage/degrees/download',
    ADD_UPDATE_REQUISITION_ENTITIES_DEGREES: 'api/manage/degrees',
    BULK_REQUISITION_ENTITIES_DEGREES: 'api/manage/degrees/bulkDelete',
    GET_ALL_DEGREES_ACTIVITIES: 'api/manage/degrees/activity',
    UPLOAD_REQUISITION_ENTITIES_FIELD_OF_STUDY: 'api/manage/fieldsOfStudy/upload',
    DOWNLOAD_UPLOADED_REQUISITION_ENTITIES_FIELD_OF_STUDY: 'api/manage/fieldsOfStudy/file',
    GET_REQUISITION_ENTITIES_FIELD_OF_STUDY: 'api/manage/fieldsOfStudy',
    DOWNLOAD_REQUISITION_ENTITIES_FIELD_OF_STUDY: 'api/manage/fieldsOfStudy/download',
    ADD_UPDATE_REQUISITION_ENTITIES_FIELD_OF_STUDY: 'api/manage/fieldsOfStudy',
    BULK_REQUISITION_ENTITIES_FIELD_OF_STUDY: 'api/manage/fieldsOfStudy/bulkDelete',
    GET_ALL_FIELD_OF_STUDY_ACTIVITIES: 'api/manage/fieldsOfStudy/activity',
    UPLOAD_REQUISITION_ENTITIES_CANDIDATE_ROLES: 'api/manage/candidateroles/upload',
    DOWNLOAD_UPLOADED_REQUISITION_ENTITIES_CANDIDATE_ROLES: 'api/manage/candidateroles/file',
    GET_REQUISITION_ENTITIES_CANDIDATE_ROLES: 'api/manage/candidateroles',
    DOWNLOAD_REQUISITION_ENTITIES_CANDIDATE_ROLES: 'api/manage/candidateroles/download',
    ADD_UPDATE_REQUISITION_ENTITIES_CANDIDATE_ROLES: 'api/manage/candidateroles',
    BULK_REQUISITION_ENTITIES_CANDIDATE_ROLES: 'api/manage/candidateroles/bulkDelete',
    GET_ALL_CANDIDATE_ROLES_ACTIVITIES: 'api/manage/candidateroles/activity',
    UPLOAD_REQUISITION_ENTITIES_CERTIFICATIONS: 'api/manage/certifications/upload',
    DOWNLOAD_UPLOADED_REQUISITION_ENTITIES_CERTIFICATIONS: 'api/manage/certifications/file',
    GET_REQUISITION_ENTITIES_CERTIFICATIONS: 'api/manage/certifications',
    DOWNLOAD_REQUISITION_ENTITIES_CERTIFICATIONS: 'api/manage/certifications/download',
    ADD_UPDATE_REQUISITION_ENTITIES_CERTIFICATIONS: 'api/manage/certifications',
    BULK_REQUISITION_ENTITIES_CERTIFICATIONS: 'api/manage/certifications/bulkDelete',
    GET_ALL_CERTIFICATIONS_ACTIVITIES: 'api/manage/certifications/activity',
    UPLOAD_REQUISITION_ENTITIES_JD_ACTIONS: 'api/manage/actionwords/upload',
    DOWNLOAD_UPLOADED_REQUISITION_ENTITIES_JD_ACTIONS: 'api/manage/actionwords/file',
    GET_REQUISITION_ENTITIES_JD_ACTIONS: 'api/manage/actionwords',
    DOWNLOAD_REQUISITION_ENTITIES_JD_ACTIONS: 'api/manage/actionwords/download',
    ADD_UPDATE_REQUISITION_ENTITIES_JD_ACTIONS: 'api/manage/actionwords',
    BULK_REQUISITION_ENTITIES_JD_ACTIONS: 'api/manage/actionwords/bulkDelete',
    GET_ALL_JD_ACTIONS_ACTIVITIES: 'api/manage/actionwords/activity',
    GET_ALL_ACTION_WORDS: 'api/manage/unknown/master-list',
    ADD_UPDATE_ACTION_WORDS: 'api/manage/unknown/master-list'
  };

  constructor(
    @Inject('$state') public $state: any,
    @Inject('$rootScope') public $rootScope: any,
    @Inject(StorageService) private StorageService: StorageService,
    @Inject(GenericService) private genericService: GenericService,
    private authService: AuthService
  ) { }

  addAndUpdateAndMoveSkill(skillObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_MOVE_SKILL + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id , skillObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllSkills(skillFilterObject, successCallback, errorCallback) {
    let queryParams = [];
    if (skillFilterObject.searchSkill && skillFilterObject.searchSkill != '') {
      queryParams.push(`searchSkill=${skillFilterObject.searchSkill}`);
    }
    if (skillFilterObject.searchByParentSkill && skillFilterObject.searchByParentSkill != '') {
      queryParams.push(`searchByParentSkill=${skillFilterObject.searchByParentSkill}`);
    }
    if (skillFilterObject.searchCategory && skillFilterObject.searchCategory != '') {
      queryParams.push(`searchCategory=${skillFilterObject.searchCategory}`);
    }
    if (skillFilterObject.sortColumn) {
      queryParams.push(`sortColumn=${skillFilterObject.sortColumn}`);
    }
    if (skillFilterObject.sortDir) {
      queryParams.push(`sortDir=${skillFilterObject.sortDir}`);
    }
    if (!_.isNull(skillFilterObject.includeAlternateSkills) || !_.isUndefined(skillFilterObject.includeAlternateSkills)) {
      queryParams.push(`includeAlternateSkills=${skillFilterObject.includeAlternateSkills}`);
    }

    let filterObj = queryParams.length ? `?${queryParams.join('&')}` : '';

    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_SKILLS + filterObj).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteSkill(skillId, boolean, successCallback, errorCallback) {
    let deleteConfirmed = '';
    if(boolean != null) {
      deleteConfirmed = `?deleteConfirmed=${boolean}`;
    }
    this.genericService.deleteObjectById(this.StorageService.get('baseurl') + this.urlConstants.DELETE_SKILL + '/' + skillId + deleteConfirmed).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteAllSkills(deleteConfirmed, skillIds, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.BULK_DELETE_SKILLS + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id + `?deleteConfirmed=${deleteConfirmed}`, {skills: skillIds}).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllCategories(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_CATEGORIES).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getSkillFileUploadUrl() {
    return this.StorageService.get('baseurl') + this.urlConstants.SKILL_UPLOAD_URL + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id;
  }

  getRequisitionEntitiesDegreesUploadUrl() {
    return this.StorageService.get('baseurl') + this.urlConstants.UPLOAD_REQUISITION_ENTITIES_DEGREES + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id;
  }

  downloadSkillFile(fileExtension, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_SKILLS_URL + '?fileExtension=' + fileExtension).subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllSkillSortCategories(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_SKILL_SORT_CATEGORIES).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  updateAllSkills(updatedSkills, successCallback, errorCallback) {
    this.genericService.putObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_MOVE_SKILL + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, updatedSkills).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllActivities(activityFilter, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_ACTIVITIES, activityFilter).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }  

  downloadUploadedFile(activityId, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_UPLOADED_FILE + "/" + activityId + "/download").subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllDegreesEntityLists(degreeFilterObject, successCallback, errorCallback) {
    let queryParams = [];
    if (degreeFilterObject.searchDegree && degreeFilterObject.searchDegree != '') {
      queryParams.push(`searchDegree=${degreeFilterObject.searchDegree}`);
    }
    if (degreeFilterObject.sortColumn) {
      queryParams.push(`sortColumn=${degreeFilterObject.sortColumn}`);
    }
    if (degreeFilterObject.sortDir) {
      queryParams.push(`sortDir=${degreeFilterObject.sortDir}`);
    }

    let filterObj = queryParams.length ? `?${queryParams.join('&')}` : '';

    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_REQUISITION_ENTITIES_DEGREES + filterObj).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  downloadDegreesFile(fileExtension, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_REQUISITION_ENTITIES_DEGREES + '?fileExtension=' + fileExtension).subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  addUpdateDegree(degree, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_DEGREES + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, degree).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  bulkAddUpdateDegrees(updateDegree, successCallback, errorCallback) {
    let payload = {
      "entities": updateDegree
    };
    this.genericService.putObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_DEGREES + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, payload).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteDegree(deletedDegree, successCallback, errorCallback) {
    this.genericService.deleteObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_DEGREES + '/' + deletedDegree.id, deletedDegree).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  bulkDeleteDegrees(deletedDegrees, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.BULK_REQUISITION_ENTITIES_DEGREES + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, { entities: deletedDegrees }).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllDegreesActivities(activityFilter, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_DEGREES_ACTIVITIES, activityFilter).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }  

  downloadUploadedDegreesFile(activityId, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_UPLOADED_REQUISITION_ENTITIES_DEGREES + "/" + activityId + "/download").subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getFieldOfStudyUploadUrl() {
    return this.StorageService.get('baseurl') + this.urlConstants.UPLOAD_REQUISITION_ENTITIES_FIELD_OF_STUDY + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id;
  }

  getAllFieldOfStudyLists(degreeFilterObject, successCallback, errorCallback) {
    let queryParams = [];
    if (degreeFilterObject.searchFieldOfStudy && degreeFilterObject.searchFieldOfStudy != '') {
      queryParams.push(`searchFieldOfStudy=${degreeFilterObject.searchFieldOfStudy}`);
    }
    if (degreeFilterObject.sortColumn) {
      queryParams.push(`sortColumn=${degreeFilterObject.sortColumn}`);
    }
    if (degreeFilterObject.sortDir) {
      queryParams.push(`sortDir=${degreeFilterObject.sortDir}`);
    }

    let filterObj = queryParams.length ? `?${queryParams.join('&')}` : '';

    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_REQUISITION_ENTITIES_FIELD_OF_STUDY + filterObj).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  downloadFieldOfStudyFile(fileExtension, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_REQUISITION_ENTITIES_FIELD_OF_STUDY + '?fileExtension=' + fileExtension).subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  addUpdateFieldOfStudy(fieldOfStudy, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_FIELD_OF_STUDY + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, fieldOfStudy).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  bulkAddUpdateFieldOfStudy(updateFieldOfStudy, successCallback, errorCallback) {
    let payload = {
      "entities": updateFieldOfStudy
    };
    this.genericService.putObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_FIELD_OF_STUDY + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, payload).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteFieldOfStudy(deletedFieldOfStudy, successCallback, errorCallback) {
    this.genericService.deleteObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_FIELD_OF_STUDY + '/' + deletedFieldOfStudy.id, deletedFieldOfStudy).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  bulkDeleteFieldOfStudy(deletedFieldOfStudyEntities, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.BULK_REQUISITION_ENTITIES_FIELD_OF_STUDY + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, { entities: deletedFieldOfStudyEntities }).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllFieldOfStudyActivities(activityFilter, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_FIELD_OF_STUDY_ACTIVITIES, activityFilter).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }  

  downloadUploadedFieldOfStudyFile(activityId, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_UPLOADED_REQUISITION_ENTITIES_FIELD_OF_STUDY + "/" + activityId + "/download").subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  downloadCandidateRolesFile(fileExtension, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_REQUISITION_ENTITIES_CANDIDATE_ROLES + '?fileExtension=' + fileExtension).subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  addUpdateCandidateRoles(candidateRoles, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_CANDIDATE_ROLES + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, candidateRoles).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  bulkAddUpdateCandidateRoles(updateCandidateRoles, successCallback, errorCallback) {
    let payload = {
      "entities": updateCandidateRoles
    };
    this.genericService.putObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_CANDIDATE_ROLES + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, payload).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteCandidateRoles(deletedCandidateRoles, successCallback, errorCallback) {
    this.genericService.deleteObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_CANDIDATE_ROLES + '/' + deletedCandidateRoles.id, deletedCandidateRoles).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  bulkDeleteCandidateRoles(deletedCandidateRolesEntities, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.BULK_REQUISITION_ENTITIES_CANDIDATE_ROLES + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, { entities: deletedCandidateRolesEntities }).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllCandidateRolesActivities(activityFilter, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_CANDIDATE_ROLES_ACTIVITIES, activityFilter).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }  

  getCandidateRolesUploadUrl() {
    return this.StorageService.get('baseurl') + this.urlConstants.UPLOAD_REQUISITION_ENTITIES_CANDIDATE_ROLES + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id;
  }

  getAllCandidateRolesLists(roleObject, successCallback, errorCallback) {
    let queryParams = [];
    if (roleObject.searchRole && roleObject.searchRole != '') {
      queryParams.push(`searchRole=${roleObject.searchRole}`);
    }
    if (roleObject.sortColumn) {
      queryParams.push(`sortColumn=${roleObject.sortColumn}`);
    }
    if (roleObject.sortDir) {
      queryParams.push(`sortDir=${roleObject.sortDir}`);
    }

    let filterObj = queryParams.length ? `?${queryParams.join('&')}` : '';

    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_REQUISITION_ENTITIES_CANDIDATE_ROLES + filterObj).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  downloadUploadedRolesFile(activityId, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_UPLOADED_REQUISITION_ENTITIES_CANDIDATE_ROLES + "/" + activityId + "/download").subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  
  downloadCertificationsFile(fileExtension, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_REQUISITION_ENTITIES_CERTIFICATIONS + '?fileExtension=' + fileExtension).subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  addUpdateCertifications(certifications, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_CERTIFICATIONS + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, certifications).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  bulkAddUpdateCertifications(updateCertifications, successCallback, errorCallback) {
    let payload = {
      "entities": updateCertifications
    };
    this.genericService.putObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_CERTIFICATIONS + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, payload).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteCertifications(deletedCertifications, successCallback, errorCallback) {
    this.genericService.deleteObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_CERTIFICATIONS + '/' + deletedCertifications.id, deletedCertifications).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  bulkDeleteCertifications(deletedCertificationsEntities, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.BULK_REQUISITION_ENTITIES_CERTIFICATIONS + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, { entities: deletedCertificationsEntities }).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllCertificationsActivities(activityFilter, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_CERTIFICATIONS_ACTIVITIES, activityFilter).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }  

  getCertificationsUploadUrl() {
    return this.StorageService.get('baseurl') + this.urlConstants.UPLOAD_REQUISITION_ENTITIES_CERTIFICATIONS + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id;
  }

  getAllCertificationsLists(certificateObject, successCallback, errorCallback) {
    let queryParams = [];
    if (certificateObject.searchName && certificateObject.searchName != '') {
      queryParams.push(`searchName=${certificateObject.searchName}`);
    }
    if (certificateObject.sortColumn) {
      queryParams.push(`sortColumn=${certificateObject.sortColumn}`);
    }
    if (certificateObject.sortDir) {
      queryParams.push(`sortDir=${certificateObject.sortDir}`);
    }

    let filterObj = queryParams.length ? `?${queryParams.join('&')}` : '';

    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_REQUISITION_ENTITIES_CERTIFICATIONS + filterObj).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  downloadUploadedCertificationsFile(activityId, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_UPLOADED_REQUISITION_ENTITIES_CERTIFICATIONS + "/" + activityId + "/download").subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  
  downloadJdActionsFile(fileExtension, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_REQUISITION_ENTITIES_JD_ACTIONS + '?fileExtension=' + fileExtension).subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  addUpdateJdActions(jdActions, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_JD_ACTIONS + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, jdActions).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  bulkAddUpdateJdActions(updateCertifications, successCallback, errorCallback) {
    let payload = {
      "entities": updateCertifications
    };
    this.genericService.putObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_JD_ACTIONS + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, payload).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteJdActions(deletedJdActions, successCallback, errorCallback) {
    this.genericService.deleteObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_JD_ACTIONS + '/' + deletedJdActions.id, deletedJdActions).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  bulkDeleteJdActions(deletedJdActionsEntities, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.BULK_REQUISITION_ENTITIES_JD_ACTIONS + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, { entities: deletedJdActionsEntities }).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllJdActionsActivities(activityFilter, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_JD_ACTIONS_ACTIVITIES, activityFilter).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }  

  getJdActionsUploadUrl() {
    return this.StorageService.get('baseurl') + this.urlConstants.UPLOAD_REQUISITION_ENTITIES_JD_ACTIONS + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id;
  }

  getAllJdActionsLists(jdActionsObject, successCallback, errorCallback) {
    let queryParams = [];
    if (jdActionsObject.searchName && jdActionsObject.searchName != '') {
      queryParams.push(`searchActionWord=${jdActionsObject.searchName}`);
    }
    if (jdActionsObject.sortColumn) {
      queryParams.push(`sortColumn=${jdActionsObject.sortColumn}`);
    }
    if (jdActionsObject.sortDir) {
      queryParams.push(`sortDir=${jdActionsObject.sortDir}`);
    }

    let filterObj = queryParams.length ? `?${queryParams.join('&')}` : '';

    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_REQUISITION_ENTITIES_JD_ACTIONS + filterObj).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  downloadUploadedJdActionsFile(activityId, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_UPLOADED_REQUISITION_ENTITIES_JD_ACTIONS + "/" + activityId + "/download").subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  // Shared Data
  setTimePeriodsInRequisitionSkillsTypeAhead() {
    let timePeriods = [];
    let a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    let b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    for (let i = 1; i < 100; i++) {
      let num: any = i;
      if ((num = num.toString()).length > 9) return 'overflow';
      let n: any = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      if (!n) return; let str = '';
      str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
      str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
      str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
      str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
      str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + '' : '';
      let yearText = "";
      let monthText = "";
      if (num > 1) {
        yearText = "years of ";
        monthText = "months of ";
      } else {
        yearText = "year of ";
        monthText = "month of ";
      }
      this.setYearsOfText(num, yearText, str, i, "", timePeriods, false);
      // if(num < 12) {
      //   this.setYearsOfText(num, monthText, str, i, "", timePeriods, true);
      // }

      let yearPlus = "plus year of ";
      this.setYearsOfText(num, yearPlus, str, i, "", timePeriods, false);
      // let monthPlus = "plus month of ";
      // if(num < 12) {
      //   this.setYearsOfText(num, monthPlus, str, i, "", timePeriods, true);
      // }

      yearPlus = "plus years of ";
      this.setYearsOfText(num, yearPlus, str, i, "", timePeriods, false);
      // monthPlus = "plus months of ";
      // if(num < 12) {
      //   this.setYearsOfText(num, monthPlus, str, i, "", timePeriods, true);
      // }

      yearPlus = "+ year of ";
      this.setYearsOfText(num, yearPlus, str, i, "", timePeriods, false);
      // monthPlus = "+ month of ";
      // if(num < 12) {
      //   this.setYearsOfText(num, monthPlus, str, i, "", timePeriods, true);
      // }

      yearPlus = "+ years of ";
      this.setYearsOfText(num, yearPlus, str, i, "", timePeriods, false);
      // monthPlus = "+ months of ";
      // if(num < 12) {
      //   this.setYearsOfText(num, monthPlus, str, i, "", timePeriods, true);
      // }

      yearPlus = " + year of";
      this.setYearsOfText(num, yearPlus, str, i, "", timePeriods, false);
      // monthPlus = " + month of";
      // if(num < 12) {
      //   this.setYearsOfText(num, monthPlus, str, i, "", timePeriods, true);
      // }

      yearPlus = " + years of";
      this.setYearsOfText(num, yearPlus, str, i, "", timePeriods, false);
      // monthPlus = " + months of";
      // if(num < 12) {
      //   this.setYearsOfText(num, monthPlus, str, i, "", timePeriods, true);
      // }
    }
    this.setFromToYearsOfText(timePeriods);
    return timePeriods;
  }

  numToText(num) {
    let a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    let b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    if (num < 20) {
      return a[num].trim();
    } else {
      return (b[Math.floor(num / 10)] + ' ' + a[num % 10]).trim();
    }
  }
  

  setFromToYearsOfText(timePeriods) {
    for (let i = 1; i <= 50; i++) {
      for (let j = i + 1; j <= 50; j++) {
        let yearStartText = this.numToText(i);
        let yearEndText = this.numToText(j);
        let numericName = `${i} to ${j} years of `;
        let textName = `${yearStartText} to ${yearEndText} years of `;
        this.setYearsOfText("",numericName,"", i, j, timePeriods, false);
        this.setYearsOfText("",textName,"", i, j, timePeriods, false);
      }
    }
  }

  setMinExperienceInMonths(min, max, type) {
    if(type === 'min') {
      return min*12;
    } else if(type === 'max') {
      return 0;
    } else if(max > min) {
      return min*12;
    } else if(max <= min) {
      return 0;
    }
  } 

  setMaxExperienceInMonths(min, max, type) {
    if(type === 'max') {
      if(max <= min) {
        return min*12;
      } else {
        return max*12;
      }
    } else if(type === 'min') {
      return 0;
    } else if(max > min) {
      return max*12;
    } else if(max <= min) {
      return min*12;
    }
  } 

  setYearsOfText(num, yearText, str,minExperienceInMonths, maxExperienceInMonths, timePeriods, isMonthText) {
      let stringText = str + yearText;
      let isPlusToExists = (yearText.includes("+") ||  yearText.includes("plus"));
      let numText = "";
      if(yearText.includes("+")) {
        numText = num + yearText;
      } else {
        numText = num + " " + yearText;
      }
      let isToExists = (yearText.includes(" to "));
      timePeriods.push({ name: stringText, value: { minExperienceInMonths: isMonthText ? num : this.setMinExperienceInMonths(minExperienceInMonths,maxExperienceInMonths, 'atleast'), maxExperienceInMonths: this.setMaxExperienceInMonths(minExperienceInMonths,maxExperienceInMonths, 'atleast'), months: isMonthText ? num : 0, range: "", plusTo: isPlusToExists ? '+' : isToExists ? 'to' : "" } });
      timePeriods.push({ name: numText, value: { minExperienceInMonths: isMonthText ? num : this.setMinExperienceInMonths(minExperienceInMonths,maxExperienceInMonths, 'atleast'), maxExperienceInMonths: this.setMaxExperienceInMonths(minExperienceInMonths,maxExperienceInMonths, 'atleast'), months: isMonthText ? num : 0, range: "", plusTo: isPlusToExists ? '+' : isToExists ? 'to' : "" } });
      timePeriods.push({ name: 'Minimum ' + str + yearText, value: { minExperienceInMonths: isMonthText ? num : this.setMinExperienceInMonths(minExperienceInMonths,maxExperienceInMonths,'min'), maxExperienceInMonths: this.setMaxExperienceInMonths(minExperienceInMonths,maxExperienceInMonths,'min'), months: isMonthText ? num : 0, range: "Minimum", plusTo: isPlusToExists ? '+' : isToExists ? 'to' : "" } });
      timePeriods.push({ name: 'Maximum ' + str + yearText, value: { minExperienceInMonths: isMonthText ? num : this.setMinExperienceInMonths(minExperienceInMonths,maxExperienceInMonths, 'max'), maxExperienceInMonths: this.setMaxExperienceInMonths(minExperienceInMonths,maxExperienceInMonths, 'max'), months: isMonthText ? num : 0, range: "Maximum", plusTo: isPlusToExists ? '+' : isToExists ? 'to' : "" } });
      timePeriods.push({ name: 'Atleast ' + str + yearText, value: { minExperienceInMonths: isMonthText ? num : this.setMinExperienceInMonths(minExperienceInMonths,maxExperienceInMonths,'atleast'), maxExperienceInMonths: this.setMaxExperienceInMonths(minExperienceInMonths,maxExperienceInMonths,'atleast'), months: isMonthText ? num : 0, range: "Atleast", plusTo: isPlusToExists ? '+' : isToExists ? 'to' : "" } });
      timePeriods.push({ name: 'Minimum ' + numText, value: { minExperienceInMonths: isMonthText ? num : this.setMinExperienceInMonths(minExperienceInMonths,maxExperienceInMonths, 'min'), maxExperienceInMonths: this.setMaxExperienceInMonths(minExperienceInMonths,maxExperienceInMonths, 'min'), months: isMonthText ? num : 0, range: "Minimum", plusTo: isPlusToExists ? '+' : isToExists ? 'to' : "" } });
      timePeriods.push({ name: 'Maximum ' + numText, value: { minExperienceInMonths: isMonthText ? num : this.setMinExperienceInMonths(minExperienceInMonths,maxExperienceInMonths, 'max'), maxExperienceInMonths: this.setMaxExperienceInMonths(minExperienceInMonths,maxExperienceInMonths, 'max'), months: isMonthText ? num : 0, range: "Maximum", plusTo: isPlusToExists ? '+' : isToExists ? 'to' : "" } });
      timePeriods.push({ name: 'Atleast ' + numText, value: { minExperienceInMonths: isMonthText ? num : this.setMinExperienceInMonths(minExperienceInMonths,maxExperienceInMonths, 'alteast'), maxExperienceInMonths: this.setMaxExperienceInMonths(minExperienceInMonths,maxExperienceInMonths, 'atleast'), months: isMonthText ? num : 0, range: "Atleast", plusTo: isPlusToExists ? '+' : isToExists ? 'to' : "" } });
  }

  setOfWordToTimePeriods(timePeriods) {
    timePeriods.forEach(timePeriod => {
      timePeriod.name = timePeriod.name + 'of ';
    });
    return timePeriods;
  }

  setInWordToTimePeriods(timePeriods) {
    timePeriods.forEach(timePeriod => {
      timePeriod.name = timePeriod.name + 'in ';
    });
    return timePeriods;
  }

  setExperienceInWordToTimePeriods(timePeriods) {
    timePeriods.forEach(timePeriod => {
      timePeriod.name = timePeriod.name + 'experience ';
    });
    
    return timePeriods;
  }

  setExperienceAndInWordToTimePeriods(timePeriods) {
    timePeriods.forEach(timePeriod => {
      timePeriod.name = timePeriod.name + 'experience in ';
    });
    
    return timePeriods;
  }

  setExperienceAndWithWordToTimePeriods(timePeriods) {
    timePeriods.forEach(timePeriod => {
      timePeriod.name = timePeriod.name + 'experience with ';
    });
    
    return timePeriods;
  }

  getAllActionWords(actionWordsFilerObject, successCallback, errorCallback) {
    let queryParams = [];
    if (actionWordsFilerObject.searchWords && actionWordsFilerObject.searchWords != '') {
      queryParams.push(`searchWords=${actionWordsFilerObject.searchWords}`);
    }
    if (actionWordsFilerObject.sortColumns) {
      queryParams.push(`sortColumns=${actionWordsFilerObject.sortColumns}`);
    }
    if (actionWordsFilerObject.sortDir) {
      queryParams.push(`sortDir=${actionWordsFilerObject.sortDir}`);
    }
    if (actionWordsFilerObject.resolved) {
      queryParams.push(`resolved=${actionWordsFilerObject.resolved}`);
    }
    if (actionWordsFilerObject.recognitionStatus) {
      queryParams.push(`recognitionStatus=${actionWordsFilerObject.recognitionStatus}`);
    }
    if (actionWordsFilerObject.ignore) {
      queryParams.push(`ignore=${actionWordsFilerObject.ignore}`);
    }

    let filterObj = queryParams.length ? `?${queryParams.join('&')}` : '';

    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_ACTION_WORDS + filterObj).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }  

  addUpdateActionWords(actionWords, successCallback, errorCallback) {
    this.genericService.updateObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_ACTION_WORDS, actionWords).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }
  
  escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
  }

  getSkillsAsObservable(token: string, skillType: string, data: any): Observable<any> {
    const escapedToken = this.escapeRegExp(token);
    const query = new RegExp(escapedToken, 'i');
    this.skillType = skillType;
    if (this.skillType === 'education') {
      if (data) {
        this.allExistingDegrees = data.degrees;
        this.allExistingFieldOfStudy = data.fieldOfStudy;
      }
      const results = this.getEducationTypeAhead(token);
      return of(results.filter((item: any) => query.test(item.name)));
    } else if (this.skillType === 'experience') {
      if (data) {
        this.allExistingFieldOfStudy = data.fieldOfStudy;
        this.allExistingRoles = data.roles;
        this.timePeriods = data.timePeriods
      }
      const results = this.getExperienceTypeAhead(token);
      return of(results.filter((item: any) => query.test(item.name)));
    } else if (this.skillType === 'certification') {
      if (data) {
        this.allExistingCertificates = data;
      }
      const results = this.getCertificationTypeAhead(token);
      return of(results.filter((item: any) => query.test(item.name)));
    } else {
      if (data) {
        this.allExistingSkills = data.skills;
        this.timePeriods = data.timePeriods
      }
      const results = this.getSkill(token);
      return of(results.filter((item: any) => query.test(item.displayName)));
    }
  }

  createSkillObservable(
    skillType: string,
    getTextFn: () => string,
    data: any
  ): Observable<any> {
    return Observable.create((observer: any) => {
      observer.next(getTextFn());
    }).pipe(
      mergeMap((token: string) => {
        return this.getSkillsAsObservable(token, skillType, data);
      })
    );
  }

  // Educations

  _searchStringHasDegreesInfoButNoFieldOfStudy(searchText) {
    let degreesResult = this.allExistingDegrees.filter((degree) => {
      return degree.degreeName.toLowerCase().includes(searchText);
    });
    return degreesResult.length > 0;
  }

  _getDegreesForSearchString(searchText) {
    let degreesResult = this.allExistingDegrees.filter((degree) => {
      return degree.degreeName.toLowerCase().includes(searchText);
    });
    let degreesObjectArray = [];
    degreesResult.forEach(degree => {
      degreesObjectArray.push({ displayName: degree.name, name: degree.name, degreeName: degree.name });
    })
    return degreesObjectArray;
  }

  setEducationFieldOfStudy() {
    let updatedFields = [];

    this.allExistingFieldOfStudy.forEach(fieldOfStudy => {
      const variations = [
        { suffix: '' },
        { suffix: ' or equivalent' },
        { suffix: ' or equivalent experience' },
        { suffix: ' or equivalent experience in related field' }
      ];

      variations.forEach(variation => {
        updatedFields.push({
          ...fieldOfStudy,
          id: `${fieldOfStudy.id}${variation.suffix}`,
          competency: `${fieldOfStudy.fieldOfStudy}${variation.suffix}`,
          fieldOfStudy: `${fieldOfStudy.fieldOfStudy}${variation.suffix}`
        });
      });
    });

    return updatedFields;
  }

  // These should ideally make real API calls or return mock data
  getEducationTypeAhead(searchText: string): any[] {
    let searchResults = [];
    searchText = searchText.toLowerCase();

    if (this._searchStringHasDegreesInfoButNoFieldOfStudy(searchText)) {
      return this._getDegreesForSearchString(searchText);
    }

    let searchDegreeObj;
    let preText = "";
    let isSearchStringHasDegrees = false;
    let actualSearchText = "";
    this.allExistingDegrees.forEach(degree => {
      if (searchText.includes(degree.degreeName.toLowerCase()) && !searchText.includes(' or equivalent')) {
        isSearchStringHasDegrees = true;
        preText = degree.degreeName;
        actualSearchText = "";
        actualSearchText = searchText.replace(preText.toLowerCase(), "");
        if (actualSearchText.includes(" in ")) {
          actualSearchText = actualSearchText.replace(" in ", "");
        } else if (actualSearchText.includes(" in")) {
          actualSearchText = actualSearchText.replace(" in", "");
        }
        searchDegreeObj = _.cloneDeep(degree);
        isSearchStringHasDegrees = true;
      }
    });

    if (!isSearchStringHasDegrees) {
      actualSearchText = searchText;
    }

    this.educationFieldOfStudy = this.educationFieldOfStudy.length == 0 ? this.setEducationFieldOfStudy() : this.educationFieldOfStudy;

    let validFieldOfStudy = this.educationFieldOfStudy.filter((fieldOfStudy) => {
      return fieldOfStudy.fieldOfStudy.toLowerCase().includes(actualSearchText);
    });

    if (isSearchStringHasDegrees && validFieldOfStudy.length > 0) {
      validFieldOfStudy.forEach(fieldOfStudy => {
        searchResults.push(
          {
            displayName: searchDegreeObj.name + fieldOfStudy.fieldOfStudy,
            name: searchDegreeObj.name + fieldOfStudy.fieldOfStudy,
            experience: 0,
            mandatory: false,
            favorite: false,
            niceToHave: false,
            preferred: false,
            priority: false,
            id: fieldOfStudy.id,
            degreeName: searchDegreeObj.degreeName,
            fieldOfStudy: fieldOfStudy.fieldOfStudy,
            skillType: 'education'
          });
      });
    } else {
      validFieldOfStudy.forEach(fieldOfStudy => {
        searchResults.push(
          {
            displayName: fieldOfStudy.fieldOfStudy,
            name: fieldOfStudy.fieldOfStudy,
            experience: 0,
            mandatory: false,
            favorite: false,
            niceToHave: false,
            preferred: false,
            priority: false,
            id: fieldOfStudy.id,
            degreeName: "",
            fieldOfStudy: fieldOfStudy.fieldOfStudy,
            skillType: 'education'
          });
      });
    }
    return searchResults;
  }

  // Experiences

  _searchStringHasYearsInfoButNoSkill(searchText, type) {
    let yearsResult = [];
    if (type === 'skill') {
      yearsResult = this.timePeriods.filter((period) => {
        return period.name.toLowerCase().includes(searchText);
      });
    } else {
      yearsResult = this.timePeriods.filter((period) => {
        return period.name.toLowerCase().includes(searchText);
      });
    }
    return yearsResult.length > 0;
  }

  _getYearsForSearchString(searchText, type) {
    let yearsResult = []
    if (type === 'skill') {
      yearsResult = this.timePeriods.filter((period) => {
        return period.name.toLowerCase().includes(searchText);
      });
    } else {
      yearsResult = this.timePeriods.filter((period) => {
        return period.name.toLowerCase().includes(searchText);
      });
    }
    let yearsObjectArray = [];
    angular.forEach(yearsResult, (year, key) => {
      yearsObjectArray.push({ displayName: year.name, name: year.name });
    });
    return yearsObjectArray;
  }

  setExperienceRoles() {
    let updatedFields = [];
    // const variations = [
    //     '',
    //     'in similar role',
    //     'in a fast-paced environment' ,
    //     'in a technical environment',
    //     'in development',
    //     'in development',
    //     'in senior management',
    //     'in senior leadership', 
    //     'in managerial role', 
    //     'in leadership capacity', 
    //     'in managing projects', 'managing teams', 
    //     'in supervising teams', 
    //     'in supervising a team', 
    //     'in managing projects ot teams', 
    //     'in mid-level management'
    // ];
    // variations.forEach(variation => {
    //     updatedFields.push({
    //         id: `${variation}-id`,
    //         competencyWithPreposition: variation,
    //         competency: variation.includes("in a") ? variation.replace("in a ", "") : variation.replace("in ", ""),
    //         role: variation.includes("in a") ? variation.replace("in a ", "") : variation.replace("in ", "")
    //     });
    // });

    this.allExistingFieldOfStudy.forEach(fieldOfStudy => {
      updatedFields.push({
        ...fieldOfStudy,
        competencyWithPreposition: `in ${fieldOfStudy.fieldOfStudy}`,
        competency: `${fieldOfStudy.fieldOfStudy}`,
        role: `${fieldOfStudy.fieldOfStudy}`
      });
    });

    // this.allExistingRoles.forEach(role => {
    //     updatedFields.push({
    //         ...role,
    //         competencyWithPreposition: `as a ${role.role}`,
    //         competency: `${role.role}`
    //     });
    // });

    return updatedFields;
  }

  setExperiencePrepositionValue(role) {
    const regex = /^(in|as)(\s+a\b)?/i;  // Ensures "in a" or "as a" only match if "a" is a separate word
    const match = role.match(regex);
    return match ? match[0].trim() : ''; // Ensure no trailing spaces
  }

  getExperienceTypeAhead(searchText: string): any[] {
    let searchResults = [];
    searchText = searchText.toLowerCase();

    if (this._searchStringHasYearsInfoButNoSkill(searchText, 'role')) {
      return this._getYearsForSearchString(searchText, 'role');
    }

    let periodObject;
    let preText = "";
    let isSearchStringHasYears = false;
    let actualSearchText = "";
    let existingTimePeriod = this.timePeriods.filter(timePeriod => (searchText.includes(timePeriod.name.toLowerCase()))).sort((a, b) => b.name.length - a.name.length)[0];
    if (existingTimePeriod) {
      isSearchStringHasYears = true;
      preText = existingTimePeriod.name;
      actualSearchText = "";
      actualSearchText = searchText.replace(preText.toLowerCase(), "");
      periodObject = angular.copy(existingTimePeriod);
    }

    if (!isSearchStringHasYears) {
      actualSearchText = searchText;
    }

    this.experienceRoles = this.experienceRoles.length == 0 ? this.setExperienceRoles() : this.experienceRoles;

    let validRoles = this.experienceRoles.filter((role) => {
      return role.competencyWithPreposition.toLowerCase().includes(actualSearchText);
    });

    if (isSearchStringHasYears && validRoles.length > 0) {
      validRoles.forEach(role => {
        searchResults.push(
          {
            id: role.role.id,
            name: preText + role.competencyWithPreposition,
            mandatory: false,
            favorite: false,
            preferred: false,
            niceToHave: false,
            range: periodObject.value ? periodObject.value.range : "",
            minExperienceInMonths: periodObject.value ? periodObject.value.minExperienceInMonths : "",
            maxExperienceInMonths: periodObject.value ? periodObject.value.maxExperienceInMonths : "",
            plusTo: periodObject.value ? periodObject.value.plusTo : "",
            preposition: periodObject.value ? this.setExperiencePrepositionValue(role.competencyWithPreposition) : "",
            role: role.competency,
            displayName: preText + role.competencyWithPreposition,
            skillType: 'experience'
          });
      });
    } else if (validRoles.length > 0) {
      validRoles.forEach(role => {
        searchResults.push(
          {
            displayName: role.competency,
            name: role.competency,
            id: role.role.id,
            mandatory: false,
            favorite: false,
            preferred: false,
            niceToHave: false,
            role: role.competency,
            skillType: 'experience'
          });
      });
    }
    return searchResults;
  }

  // Skills

  checkIfSearchStringHasBrackets(searchText: string, type: 'startWith' | 'endWith'): boolean {
    // Check if the search string starts or ends with a bracket
    return type === 'startWith' ? searchText.startsWith('(') : searchText.endsWith(')');
  }

  searchSkillHasAndOrNil(text: string) {
    // Check if the string contains 'AND', 'OR', or 'NIL' (case-insensitive)
    if (!/\b(and|or|nil)\b/i.test(text)) {
      return { allString: [], lastString: null };
    }

    // Split the text based on 'AND', 'OR', or 'NIL' (case-insensitive)
    const allString = text.split(/\b(?:and|or|nil)\b/i).map(part => part.trim());
    const lastString = allString.length > 0 ? allString[allString.length - 1] : null;

    return { allString, lastString };
  }

  setSearchStringWithTimePeriods(searchText) {
    // return results with time periods
    searchText = searchText.toLowerCase();
    if (this._searchStringHasYearsInfoButNoSkill(searchText, 'skill')) {
      return this._getYearsForSearchString(searchText, 'skill');
    } else {
      return [];
    }
  }

  convertMonthsToYearsText(periodValue, skill, displayName) {
    const wordToNumber = {
      "one": 1,
      "two": 2,
      "three": 3,
      "four": 4,
      "five": 5,
      "six": 6,
      "seven": 7,
      "eight": 8,
      "nine": 9,
      "ten": 10,
      "eleven": 11,
      "twelve": 12
    };

    // Regex to capture numeric or word-based months, with optional 'plus'/'+' or "At least/Minimum/Maximum"
    let monthsPattern = /(?:Atleast|Minimum|Maximum)?\s*(\d+|one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve)\s*(?:\+|plus)?\s*month(?:s)?/i;

    let match = displayName.match(monthsPattern);

    if (match) {
      let monthsString = match[1].toLowerCase();
      let months = isNaN(monthsString) ? wordToNumber[monthsString] : parseInt(monthsString);  // Convert word to number or use the numeric value

      let prefix = '';
      let suffix = '';

      // Check for "Atleast", "Minimum", "Maximum" and append them to the prefix
      if (/Atleast/i.test(displayName)) {
        prefix = 'Atleast ';
      } else if (/Minimum/i.test(displayName)) {
        prefix = 'Minimum ';
      } else if (/Maximum/i.test(displayName)) {
        prefix = 'Maximum ';
      }

      // Adjust for "plus" or "+" indicating additional months and update suffix
      if (match[0].includes('plus') || match[0].includes('+')) {
        suffix = ' +'; // Add the plus suffix
      }

      let years = (months / 12).toFixed(1);  // Convert months to years and round to 1 decimal place

      // Extract the skill from the displayName by removing the matched time period
      let skillName = displayName.replace(match[0], '').trim();

      return `${prefix}${years}${suffix} years ${skillName}`;
    } else {
      return '';
    }
  }

  setSearchStringWithSkills(searchText) {
    // return results with skills
    let searchResults = [];
    let timePeriodObj = this.timePeriods.filter(timePeriod => searchText.toLowerCase().includes(timePeriod.name.toLowerCase()))
      .sort((a, b) => b.name.length - a.name.length)[0];

    let safeName = timePeriodObj ? timePeriodObj.name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : "";
    let existingSkillName = timePeriodObj ? searchText.replace(new RegExp(`^${safeName}`, 'i'), '').trim() : searchText;

    let preText = timePeriodObj ? timePeriodObj.name : "";
    let isSearchStringHasYears = timePeriodObj ? true : false;
    let actualSearchText = existingSkillName;

    if (!isSearchStringHasYears) {
      actualSearchText = searchText;
    }

    let validSkills = this.allExistingSkills
      .filter((skill) => {
        return skill && skill.skill && skill.skill.toLowerCase().includes(actualSearchText.toLowerCase());
      })
      .sort((a, b) => a.skill.length - b.skill.length);

    if (isSearchStringHasYears && validSkills.length > 0) {
      angular.forEach(validSkills, (skill, key) => {
        let addMonthsText = (timePeriodObj && timePeriodObj.value.months > 0) ? this.convertMonthsToYearsText(timePeriodObj.value, skill.skill, (preText + skill.skill)) : '';
        searchResults.push(
          {
            displayName: preText + skill.skill + " ",
            convertedDisplayName: addMonthsText,
            name: skill.skill,
            experience: timePeriodObj.value,
            mandatory: false,
            favorite: false,
            niceToHave: false,
            preferred: false,
            priority: false,
            id: skill.id,
            skill: skill,
            competency: skill.skill,
            range: timePeriodObj.value ? timePeriodObj.value.range : "",
            minExperienceInMonths: timePeriodObj.value ? timePeriodObj.value.minExperienceInMonths : "",
            maxExperienceInMonths: timePeriodObj.value ? timePeriodObj.value.maxExperienceInMonths : "",
            plusTo: timePeriodObj.value ? timePeriodObj.value.plusTo : "",
            actionWord: timePeriodObj.value.isJDActionWord ? timePeriodObj.value.jdAction.name : null,
            skillType: 'skills'
          });
      });
    } else if (validSkills.length > 0) {
      angular.forEach(validSkills, (skill, key) => {
        searchResults.push(
          {
            displayName: skill.skill,
            name: skill.skill,
            experience: skill.experience,
            mandatory: false,
            favorite: false,
            niceToHave: false,
            preferred: false,
            priority: false,
            id: skill.id,
            skill: skill,
            competency: skill.skill,
            skillType: 'skills'
          });
      });
    }
    return searchResults;
  }

  setSearchSkillWithTimePeriodsAndSkills(searchText) {
    // return results with time periods and skills
    let searchResults = [];
    searchText = searchText.toLowerCase();
    searchResults = this.setSearchStringWithTimePeriods(searchText);
    if (searchResults.length > 0) {
      return searchResults;
    } else {
      searchResults = this.setSearchStringWithSkills(searchText);
      return searchResults;
    }
  }

  endsWithLogicalWord(text: string): boolean {
    // Check if the text ends with 'AND', 'OR', or 'NIL' (case-insensitive)
    return /\b(?:AND|OR|NIL)\s*$/i.test(text);
  }

  setSearchStringWithBrackets(searchText: string, searchResults: any[], preText): any[] {
    const searchSkillHasAndOr = this.searchSkillHasAndOrNil(searchText);

    if (searchSkillHasAndOr.lastString) {
      if (searchSkillHasAndOr.lastString === '(') {
        // Recursively handle cases where the last string is an opening bracket
        return searchResults = this.setSearchStringWithBrackets(searchSkillHasAndOr.lastString, searchResults, searchText);
      } else {
        // Handle search results based on lastString
        let tempSearchText = searchSkillHasAndOr.lastString.startsWith("(") ? searchSkillHasAndOr.lastString.slice(1) : searchSkillHasAndOr.lastString;
        searchResults = this.setSearchSkillWithTimePeriodsAndSkills(tempSearchText);
        const lastIndex = searchText.lastIndexOf(searchSkillHasAndOr.lastString);
        const preText = lastIndex !== -1 ? searchText.slice(0, lastIndex).trim() : "";

        searchResults.forEach(result => {
          if (searchSkillHasAndOr.lastString.startsWith("(")) {
            result.displayName = preText + ' (' + result.displayName;
          } else {
            result.displayName = `${preText} ${result.displayName}`;
          }
        });

        if (
          (searchResults.length === 0 || searchResults.length === 1) &&
          !this.endsWithLogicalWord(searchText) &&
          searchText.endsWith(" ")
        ) {
          let displayName = "";
          if (preText) {
            displayName = searchText.includes(preText) ? searchText : preText;
          } else {
            displayName = '(' + searchText;
          }
          searchResults.push({ displayName: displayName + 'AND', name: 'AND' });
          searchResults.push({ displayName: displayName + 'OR', name: 'OR' });
          searchResults.push({ displayName: displayName + 'NIL', name: 'NIL' });
        }
        return searchResults;
      }
    } else {
      // Process the case where no logical operator exists in the lastString
      let tempSearchText = searchText.startsWith("(") ? searchText.slice(1) : searchText;
      searchResults = this.setSearchStringWithTimePeriods(tempSearchText);

      if (searchResults.length > 0) {
        searchResults.forEach(result => {
          result.displayName = preText ? preText + result.displayName : '(' + result.displayName;
        });
      } else {
        searchResults = this.setSearchStringWithSkills(tempSearchText);

        if (
          (searchResults.length === 0 || searchResults.length === 1) &&
          !this.endsWithLogicalWord(tempSearchText) &&
          tempSearchText.endsWith(" ")
        ) {
          let displayName = preText ? preText + tempSearchText : '(' + tempSearchText;
          searchResults.push({ displayName: displayName + 'AND', name: 'AND' });
          searchResults.push({ displayName: displayName + 'OR', name: 'OR' });
          searchResults.push({ displayName: displayName + 'NIL', name: 'NIL' });
        } else {
          searchResults.forEach(result => {
            result.displayName = preText ? preText + result.displayName : '(' + result.displayName;
          });
        }
      }
      return searchResults;
    }
  }

  getSkill(searchText: string): any[] {
    let searchResults: any[] = [];
    const checkIfStartsWithBrackets = this.checkIfSearchStringHasBrackets(searchText, 'startWith');

    if (checkIfStartsWithBrackets) {
      // return results if search string has open bracket
      searchResults = this.setSearchStringWithBrackets(searchText, [], null);
    } else {
      // return results if search string has no bracket
      const searchSkillHasAndOr = this.searchSkillHasAndOrNil(searchText);

      if (searchSkillHasAndOr.lastString) {
        searchResults = this.setSearchSkillWithTimePeriodsAndSkills(searchSkillHasAndOr.lastString);
        const lastIndex = searchText.lastIndexOf(searchSkillHasAndOr.lastString);
        const preText = lastIndex !== -1 ? searchText.slice(0, lastIndex).trim() : "";

        searchResults.forEach(result => {
          result.displayName = `${preText} ${result.displayName}`;
        });

        if (
          (searchResults.length === 0 || searchResults.length === 1) &&
          !this.endsWithLogicalWord(searchText) &&
          searchText.endsWith(" ")
        ) {
          searchResults.push({ displayName: searchText + 'AND', name: 'AND' });
          searchResults.push({ displayName: searchText + 'OR', name: 'OR' });
          searchResults.push({ displayName: searchText + 'NIL', name: 'NIL' });
        }
      } else {
        const tempSearchText = searchText.trim();
        searchResults = this.setSearchStringWithTimePeriods(tempSearchText);

        if (searchResults.length === 0) {
          searchResults = this.setSearchStringWithSkills(tempSearchText);

          if (
            (searchResults.length === 0 || searchResults.length === 1) &&
            !this.endsWithLogicalWord(tempSearchText) &&
            tempSearchText.endsWith(" ")
          ) {
            searchResults.push({ displayName: tempSearchText + 'AND', name: 'AND' });
            searchResults.push({ displayName: tempSearchText + 'OR', name: 'OR' });
            searchResults.push({ displayName: tempSearchText + 'NIL', name: 'NIL' });
          }
        }
      }
    }
    return searchResults;
  }

  // Certifications

  getCertificationTypeAhead(searchText: string): any[] {
    let searchResults = [];
    searchText = searchText.toLowerCase();

    if (this.searchStringHasCertification(searchText)) {
      return this.getCertificatesForSearchString(searchText);
    }

    let searchCertificateObj;
    let preText = "";
    let isSearchStringHasCertificates = false;
    let actualSearchText = "";
    this.allExistingCertificates.forEach(certificate => {
      if (searchText.includes(certificate.group.toLowerCase())) {
        isSearchStringHasCertificates = true;
        preText = certificate.group;
        actualSearchText = "";
        actualSearchText = searchText.replace(preText.toLowerCase(), "");
        if (actualSearchText.includes(" in ")) {
          actualSearchText = actualSearchText.replace(" in ", "");
        } else if (actualSearchText.includes(" in")) {
          actualSearchText = actualSearchText.replace(" in", "");
        }
        searchCertificateObj = _.cloneDeep(certificate);
      }
    });

    if (!isSearchStringHasCertificates) {
      actualSearchText = searchText;
    }

    let validSkills = this.allExistingCertificates.filter((certificate) => {
      return certificate.name.toLowerCase().includes(actualSearchText);
    });

    if (isSearchStringHasCertificates && validSkills.length > 0) {
      validSkills.forEach(certificate => {
        searchResults.push(
          {
            displayName: searchCertificateObj.inName + certificate.name,
            name: searchCertificateObj.inName + certificate.name,
            experience: 0,
            mandatory: false,
            favorite: false,
            id: certificate.id,
            certificationGroup: searchCertificateObj.group,
            certificationSkill: certificate.name,
            skillType: 'certification'
          });
      });
    } else {
      validSkills.forEach(certificate => {
        searchResults.push(
          {
            displayName: certificate.name,
            name: certificate.name,
            experience: 0,
            mandatory: false,
            favorite: false,
            id: certificate.id,
            certificationGroup: "",
            certificationSkill: certificate.name,
            skillType: 'certification'
          });
      });
    }
    return searchResults;
  }

  searchStringHasCertification(searchText) {
    let certificatesResult = this.allExistingCertificates.filter((certificate) => {
      return certificate.group.toLowerCase().includes(searchText);
    });
    return certificatesResult.length > 0;
  }

  getCertificatesForSearchString(searchText) {
    let certificatesResult = this.allExistingCertificates.filter((certificate) => {
      return certificate.group.toLowerCase().includes(searchText);
    });
    let certificateObjectArray = [];
    certificatesResult.forEach(certificate => {
      certificateObjectArray.push({ displayName: certificate.inName, name: certificate.inName });
    })
    return certificateObjectArray;
  }

}
